import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AccountService from '../../services/account-service'
import { useToasts } from 'react-toast-notifications'
import useUser from '../../hooks/useUser'
import { Loader } from '../ui/molecules/Loader'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import FormUtils from '../../utils/form-utils'
import { Input } from '../ui/atoms/Input'
import Select from '../ui/atoms/Select'
import { Autocomplete } from '../ui/atoms/Autocomplete'
import Sectors from '../../assets/json/sectors.json'
import StudyAreas from '../../assets/json/study-areas.json'
import SeniorityLevels from '../../assets/json/seniority-levels.json'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const ProfessionalInfo = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { user, reloadUserInfo } = useUser()
  const [loading, setLoading] = useState(false)
  const [sectors, setSectors] = useState([])
  const [seniorityLevels, setSeniorityLevels] = useState([])


  const {
    register,
    handleSubmit,
    trigger,
    errors,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
        sector: Yup.string().nullable(true),
        seniority: Yup.string().nullable(true),
        current_digital_freelance: Yup.string().nullable(true)
    }), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
  }

  const updatePersonalInfo = (values) => {
    setLoading(true)
    if (values.current_digital_freelance === 'false') {
        values.current_digital_freelance = false
    } else if (values.current_digital_freelance === 'true') {
        values.current_digital_freelance = true
    }
    AccountService.updatePersonalInfo(values)
      .then(() => reloadUserInfo())
      .then(() => {
        addToast(t('professional_info.professional_info_updated_successfully'), {
          appearance: 'success',
          autoDismiss: true
        })
      })
      .catch(error => {
        addToast(
          error.response && error.response.data.msg
            ? error.response.data.msg
            : t('professional_info.error_occurred_updating_professional_info'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      }).finally(() => {
        setLoading(false)
      })
  }

  const onInvalid = () => {
    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
  }

  useEffect(() => {
    register('seniority')
    register('current_digital_freelance')
    register('sector')
  }, [register])

  useEffect(() => {
    if (user) {
      _setValue('current_digital_freelance', user.current_digital_freelance)
      _setValue('sector', user.sector)
      _setValue('seniority', user.seniority)
    }
  }, [user])

  useEffect(() => {
    setSectors(Sectors.map(s => ({ id: s._id, value: t(`sectors.${s.key}`) })).sort((a, b) => {
        const nameA = a.value.toUpperCase();
        const nameB = b.value.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        // names must be equal
        return 0;
    }))
    setSeniorityLevels(SeniorityLevels.map(sl => ({ id: sl._id, value: t(`seniority.${sl.key}`) })))
  }, [])

  const YES_NO_OPTIONS = [
    { id: 'yes', value: t('yes') },
    { id: 'no', value: t('no') }
  ]

  return (
    <>
      <div className='flex flex-row justify-center w-full'>
        <div className="flex items-center w-full mt-4">
          <form
            className="flex flex-col w-full"
            onSubmit={handleSubmit(updatePersonalInfo, onInvalid)}
          >
            <div className="grid grid-cols-1 gap-1">
                <Select
                    name="current_digital_freelance"
                    label={t('professional_info.current_digital_freelance_label')}
                    reference={register}
                    placeholder={t('professional_info.current_digital_freelance_placeholder')}
                    items={YES_NO_OPTIONS}
                    onSelect={selection => _setValue('current_digital_freelance', selection.length ? selection[0].id : null)}
                    initialValues={[true, false].includes(getValues().current_digital_freelance) ? YES_NO_OPTIONS.filter((item) => getValues().current_digital_freelance ? item.id === 'yes' : item.id === 'no') : []}
                />
                <Select
                    name="sector"
                    label={t('professional_info.sector_label')}
                    reference={register}
                    placeholder={t('professional_info.sector_placeholder')}
                    items={sectors}
                    onSelect={selection => _setValue('sector', selection.length ? selection[0].id : null)}
                    initialValues={getValues().sector ? [sectors.find(g => g.id === getValues().sector)] : []}
                />
            </div>

            <div className="grid grid-cols-1 gap-1">
                <Select
                    name="seniority"
                    label={t('professional_info.seniority_label')}
                    reference={register}
                    placeholder={t('professional_info.seniority_placeholder')}
                    items={seniorityLevels}
                    onSelect={selection => _setValue('seniority', selection.length ? selection[0].id : null)}
                    initialValues={getValues().seniority ? [seniorityLevels.find(g => g.id === getValues().seniority)] : []}
                />
            </div>
            <div className='flex justify-end mt-4'>
              <ButtonMain
                width={'full lg:w-auto'}
                text={t('buttons.save')}
                type={'submit'}
              />
            </div>
          </form>
        </div>
      </div>

      {loading && <Loader />}
    </>
  )
}

export default ProfessionalInfo
