import ReactDOM from 'react-dom'
import { FaTimes } from 'react-icons/fa'
import { IoContract, IoExpand } from 'react-icons/io5'

import Config from 'config'
import { useState } from 'react'

export const SidePanel = ({ children, onClose, title, width, showExpand = false }) => {
  const close = () => {
    onClose && onClose()
  }

  const [expand, setExpand] = useState(false)

  return ReactDOM.createPortal(
    <div
      onClick={e => e.target.classList.contains('side-panel') && close()}
      className="fixed z-50 left-0 top-0 w-screen h-screen bg-gray-opacity side-panel"
    >
      <div
        style={{ animation: 'appearFromRight 0.3s ease-in-out' }}
        className={`fixed top-0 right-0 h-screen mb-16 shadow-soft-white overflow-auto overscroll-y-auto transition ${width && !expand && `w-${width}`} ${expand && 'w-10/12'}`}
        style={{ backgroundColor: "#F8F8F9" }}
      >
        <div className={`flex px-4 py-3 bg-main-${Config.PLATFORM} text-white text-lg items-center flex justify-between`}>
          <div onClick={() => onClose && onClose()}>
            <FaTimes className="inline mr-4 relative -mt-1 cursor-pointer" />
            {title}
          </div>
          {showExpand && <div>
            {expand && <IoContract className="inline mr-4 relative -mt-1 cursor-pointer" onClick={() => setExpand(!expand)} />}
            {!expand && <IoExpand className="inline mr-4 relative -mt-1 cursor-pointer" onClick={() => setExpand(!expand)} />}
          </div>}
        </div>
        <div className="p-4 pt-6">
          {children}
        </div>
      </div>
    </div>
    , document.body
  )
}
