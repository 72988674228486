import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "components/ui/molecules/Loader";
import { useHistory } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { FaChevronCircleDown, FaChevronCircleUp, FaExclamationCircle } from "react-icons/fa";
import EvaluationsService from "services/evaluations-service";
import useUser from "hooks/useUser";
import Config from "config";
import EntreCompBG from "assets/images/entrecomp.png";
import DigCompBG from "assets/images/digcomp.png";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { useSwal } from "hooks/useSwal";

const Evaluations = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { alert } = useSwal();
  const [error, setError] = useState(false);
  const [evaluations, setEvaluations] = useState({});

  const history = useHistory();

  const handleRedirect = (url) => history.push(url);

  const getEvaluations = async () => {
    try {
      setLoading(true);
      const result = await EvaluationsService.getEvaluations();
      setEvaluations(result.data.data);
      setError(false);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEvaluations();
  }, []);

  const [collapsed, setCollapsed] = useState(false);
  const expandOrCollapse = (elementId) => {
    const element = document.getElementById(elementId);
    element.classList.toggle("hidden");
    setCollapsed(!collapsed);
  };

  const hasPendingInformation = () => {
    return !(user.date_of_birth && user.gender && user.nationality);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={t("page_titles.evaluations")} showBack={false} />
        </div>
      </div>

      {!error && !loading && (
        <div>
          {hasPendingInformation() && (
            <div className="w-full p-2 mb-4 text-white rounded-lg bg-red">
              <div
                className="flex justify-between mb-2 cursor-pointer"
                onClick={() => expandOrCollapse("pending_information_list")}
              >
                <div className="flex items-center">
                  <FaExclamationCircle className="relative inline-block mr-2" />{" "}
                  {t("evaluations.incomplete_profile_warning")}
                </div>
                <div>
                  {collapsed ? (
                    <FaChevronCircleDown
                      data-panel={"pending_information_list"}
                      className={"text-white inline-block"}
                    />
                  ) : (
                    <FaChevronCircleUp data-panel={"pending_information_list"} className={"text-white inline-block"} />
                  )}
                </div>
              </div>
              <div id={"pending_information_list"}>
                <>
                  <ul className="px-8 list-disc">
                    {!user.date_of_birth && <li className="mb-1">{t("personal_info.date_of_birth")}</li>}
                    {!user.gender && <li className="mb-1">{t("personal_info.gender")}</li>}
                    {!user.nationality && <li className="mb-1">{t("personal_info.nationality")}</li>}
                  </ul>
                  <div className="flex justify-end my-1">
                    <button
                      className="p-2 text-xs bg-white rounded-lg text-red"
                      onClick={() => handleRedirect(RoutesLinks.ACCOUNT_LINK)}
                    >
                      {t("evaluations.complete_profile_button")}
                    </button>
                  </div>
                </>
              </div>
            </div>
          )}

          <div className={`w-full rounded-lg p-5 border-4 border-secondary-${Config.PLATFORM}`}>
            {t("evaluations.description")}
          </div>

          <div className="my-10">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-3 sm:grid-cols-2">
              <div className="w-full h-40 mb-4 group rounded-t-2xl">
                <div className="relative w-full h-40 overflow-hidden rounded-t-2xl">
                  <div
                    className="flex items-center justify-center w-full h-40 rounded-t-2xl"
                    style={{ backgroundImage: `url(${EntreCompBG})`, backgroundSize: "cover" }}
                  >
                    <div className="absolute inset-0 transition-opacity duration-300 bg-white bg-opacity-80 group-hover:opacity-70"></div>
                    <div className={`text-main-${Config.PLATFORM} text-center p-2 relative z-10`}>
                      <h2 className="mb-2">{t("evaluations.entrecomp")}</h2>
                      <div className="px-3 text-base font-semibold text-center text-black">
                        {t("evaluations.entrecomp_subtitle")}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="py-2 text-xs font-semibold text-center text-black cursor-pointer rounded-b-2xl"
                  style={{ backgroundColor: "#8ECAE6" }}
                  onClick={
                    hasPendingInformation()
                      ? () => alert({ text: t("evaluations.evaluation_not_available_due_to_incomplete_profile_alert") })
                      : () => handleRedirect(`${RoutesLinks.EVALUATIONS_LINK}/entrecomp`)
                  }
                >
                  {evaluations.entrecomp
                    ? evaluations.entrecomp.completed
                      ? t("evaluations.start_evaluation_button")
                      : t("evaluations.continue_evaluation_button")
                    : t("evaluations.start_evaluation_button")}
                </div>
              </div>
              <div className="w-full h-40 mb-4 group rounded-t-2xl">
                <div className="relative w-full h-40 overflow-hidden rounded-t-2xl">
                  <div
                    className="flex items-center justify-center w-full h-40 rounded-t-2xl"
                    style={{ backgroundImage: `url(${DigCompBG})`, backgroundSize: "cover" }}
                  >
                    <div className="absolute inset-0 transition-opacity duration-300 bg-white bg-opacity-80 group-hover:opacity-70"></div>
                    <div className={`text-main-${Config.PLATFORM} text-center p-2 relative z-10`}>
                      <h2 className="mb-2">{t("evaluations.digcomp")}</h2>
                      <div className="px-3 text-base font-semibold text-center text-black">
                        {t("evaluations.digcomp_subtitle")}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="py-2 text-xs font-semibold text-center text-black cursor-pointer rounded-b-2xl"
                  style={{ backgroundColor: "#ffb703" }}
                  onClick={
                    hasPendingInformation()
                      ? () => alert({ text: t("evaluations.evaluation_not_available_due_to_incomplete_profile_alert") })
                      : () => handleRedirect(`${RoutesLinks.EVALUATIONS_LINK}/digcomp`)
                  }
                >
                  {evaluations.digcomp
                    ? evaluations.digcomp.completed
                      ? t("evaluations.start_evaluation_button")
                      : t("evaluations.continue_evaluation_button")
                    : t("evaluations.start_evaluation_button")}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {error && (
        <div className="mt-6">
          <div className="flex flex-col items-center p-6 bg-white rounded-lg">
            <div className={`my-6 flex flex-row justify-center text-main-${Config.PLATFORM}`}>
              {t("evaluations.error_occurred_description")}
            </div>
            <div className="flex justify-center w-full md:w-1/4">
              <ButtonMain text={t("buttons.reload_info")} onClick={() => getEvaluations()} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Evaluations;
