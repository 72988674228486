import {
  BsReverseLayoutTextSidebarReverse,
} from 'react-icons/bs'
import {
  AiOutlinePieChart
} from 'react-icons/ai'

import { RoutesLinks } from 'components/routes-links'
import Config from 'config'
import { BiUser } from 'react-icons/bi'

const SidebarMenuItems = [
  {
    section: 'evaluations',
    title: 'sidebar.evaluations',
    url: RoutesLinks.EVALUATIONS_LINK,
    icon: <BsReverseLayoutTextSidebarReverse className={'w-6 h-4'}/>,
    iconHover: <BsReverseLayoutTextSidebarReverse className={`h-4 text-main-${Config.PLATFORM} w-6`} />
  },
  {
    section: 'reports',
    title: 'sidebar.reports',
    url: RoutesLinks.REPORTS_LINK,
    icon: <AiOutlinePieChart className={'w-6 h-5'}/>,
    iconHover: <AiOutlinePieChart className={`h-5 text-main-${Config.PLATFORM} w-6`} />
  },
  {
    section: 'account',
    title: 'sidebar.account',
    url: RoutesLinks.ACCOUNT_LINK,
    icon: <BiUser className={'w-6 h-5'}/>,
    iconHover: <BiUser className={`h-5 text-main-${Config.PLATFORM} w-6`} />,
    responsive: true
  }
]

export default SidebarMenuItems
