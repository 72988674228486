import { FaArrowLeft } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import Config from 'config'

export const PageTitle = ({
  title,
  showBack = false,
  onBackClick,
  removeMargin = false
}) => {
  const history = useHistory()

  const backClick = () => {
    if (onBackClick) {
      onBackClick()
    } else if (history.location.state && history.location.state.from) {
      history.push(history.location.state.from)
    } else {
      history.goBack()
    }
  }

  return (
    <div className={`text-3xl text-main-${Config.PLATFORM} font-bold ${removeMargin ? '' : 'mb-4'}`}>
      {showBack && (
        <FaArrowLeft
          className="inline-block mr-3 cursor-pointer"
          onClick={backClick}
        />
      )}
      {title}
    </div>
  )
}
