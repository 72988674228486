import Config from "config";
import React, { useState } from "react";
import { useLayer, Arrow } from "react-laag";

export function PopoverReport({ selectedLanguage, setSelectedLanguage }) {
  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: "es", flag: "🇪🇸" },
    { code: "en", flag: "🇬🇧" },
  ];

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageSelect = (code) => {
    setSelectedLanguage(code);
    setIsOpen(false);
  };

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: () => setIsOpen(false),
    overflowContainer: true,
    auto: true,
    placement: "bottom-center",
    triggerOffset: 12,
    arrowOffset: 8,
  });

  return (
    <div className="relative inline-block">
      <div
        {...triggerProps}
        onClick={togglePopover}
        className={`flex items-center px-2 py-1 bg-white border rounded-lg cursor-pointer border-border-buttons-secondary-${Config.PLATFORM} focus:outline-none custom-circle hover:shadow-inner`}
      >
        <span>{languages.find((lang) => lang.code === selectedLanguage)?.flag}</span>
      </div>
      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            className={`p-2 bg-white border rounded-lg shadow-lg border-border-buttons-secondary-${Config.PLATFORM}`}
          >
            <ul className="list-none">
              {languages.map((language) => (
                <li
                  key={language.code}
                  className="p-1 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleLanguageSelect(language.code)}
                >
                  {language.flag}
                </li>
              ))}
            </ul>
            <Arrow {...arrowProps} borderColor={"#8ECAE6"} borderWidth={1} />
          </div>
        )}
    </div>
  );
}
