import axios from 'axios/index'
import Config from '../config'

const ReportsService = {
  getReports: async (query) => {
    if (!query.sort) {
        delete query.sort
    }
    if (!query.evaluation_type) {
        delete query.evaluation_type
    }
    const params = new URLSearchParams(query)
    return axios.get(`${Config.DIFREE_API_URL}reports?${params}`)
  },

  getReport: async (reportId, language) => {
    return axios.get(`${Config.DIFREE_API_URL}reports/${reportId}?language=${language}`)
  }

}
export default ReportsService
