
import { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { FaTimes } from 'react-icons/fa'

export function Modal ({
  children,
  onClose,
  showCloseModal,
  showModal,
  setShowModal,
  width = 'full',
  height = 'auto',
  paddingBottom = '8',
  paddingTop = '10',
  px = '8',
  fixedWidth,
  closeOnClickOutside = false
}) {
  const wrapperRef = useRef(null)

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef
    if (closeOnClickOutside && wrap && !wrap.contains(event.target)) {
      onClose && onClose()
      setShowModal && setShowModal(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [closeOnClickOutside])

  if (!showModal) {
    return null
  }

  return ReactDOM.createPortal(
    <div className="h-full w-full | top-0 left-0 bottom-0 fixed z-50 | bg-gray-opacity | flex justify-center items-center">
      <div ref={wrapperRef} className={`${fixedWidth} relative`}>
        <div className={`max-h-screen rounded-2xl shadow-md px-${px} pb-${paddingBottom} pt-${paddingTop} w-${width} h-${height} sm:m-0 bg-white`}>
          <div className="relative top-4 right-4 | flex justify-end">
            {showCloseModal && (
              <button
                type="button"
                onClick={onClose}
                className={'p-2 rounded-full  absolute -top-9 -right-8  border border-border-buttons-secondary'}
              >
                <FaTimes className={'block w-6 text-2xl text-text-buttons-secondary'} />
              </button>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
    , document.body)
}
