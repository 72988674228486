import React from 'react'
import { useTranslation } from 'react-i18next'
import { Arrow, useLayer } from 'react-laag'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UserOperations from 'redux/user/user-operations'

import { RoutesLinks } from 'components/routes-links'
import { FaSignOutAlt, FaUser } from 'react-icons/fa'
import Config from 'config'

export function PopoverMenu () {
  const { t } = useTranslation()
  const [isOpen, setOpen] = React.useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleRedirect = (url) => {
    setOpen(false)
    history.push(url)
  }

  const handleMethod = (method) => {
    setOpen(false)
    method()
  }

  const getMenuItems = () => {
    return [
      {
        title: 'header_popover_menu.my_account',
        icon: <FaUser/>,
        investor: true,
        founder: true,
        admin: false,
        url: RoutesLinks.ACCOUNT_LINK
      },
      {
        title: 'header_popover_menu.logout',
        icon: <FaSignOutAlt />,
        investor: true,
        founder: true,
        admin: true,
        method: () => dispatch(UserOperations.logoutUser())
      }
    ]
  }

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: () => setOpen(false),
    onDisappear: () => setOpen(false),
    overflowContainer: true,
    auto: true,
    placement: 'bottom-center',
    triggerOffset: 12,
    containerOffset: 16,
    arrowOffset: 16
  })

  return (
    <>
      <button
        {...triggerProps}
        onClick={() => setOpen(!isOpen)}
        className={`bg-bg-buttons-secondary-${Config.PLATFORM} border border-border-buttons-secondary-${Config.PLATFORM} focus:outline-none custom-circle hover:shadow-inner shadow-soft-white w-12 h-12 rounded-full ml-2 flex items-center justify-center`}
      >
        <FaUser className={`text-text-buttons-secondary-${Config.PLATFORM} items-center text-center`} />
      </button>

      {isOpen &&
        renderLayer(
          <ul
            {...layerProps}
            className={`mt-2 px-2 py-2 z-30 | shadow-hover border bg-white rounded-2xl border-border-buttons-secondary-${Config.PLATFORM}`}
          >
            {getMenuItems().map((i, index) => (
                <li
                key={index}
                onClick={() =>
                  i.method ? handleMethod(i.method) : handleRedirect(i.url)
                }
                className={`px-2 py-2 flex text-main-${Config.PLATFORM} items-center cursor-pointer text-sm text-gray`}
                >
                <span className={`mr-2 text-main-${Config.PLATFORM}`}>{i.icon}</span>
                  <span className='hover:font-bold'>{t(i.title)}</span>
                </li>
            ))}
            <Arrow
              { ...arrowProps}
              borderColor={'#8ECAE6'}
              borderWidth={1}
              className={'w-5'}
            />
          </ul>
        )}
    </>
  )
}
