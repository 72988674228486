import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'localforage'
import UserReducer from './user'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import AppReducer, { AppOperations } from './app'
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

import NetworkService from '../network-service.js'
import UserOperations from './user/user-operations'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'auth', 'login_entries']
}

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['name', 'lastName', 'email', 'dni', 'group']
}

const appPersistConfig = {
  key: 'app',
  storage,
  whitelist: ['session_expired']
}

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'api_key']
}

const loginEntriesPersistConfig = {
  key: 'login_entries',
  storage,
  whitelist: ['data']
}

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, UserReducer.user),
  auth: persistReducer(authPersistConfig, UserReducer.auth),
  loginEntries: persistReducer(
    loginEntriesPersistConfig,
    UserReducer.loginEntries
  ),
  app: persistReducer(appPersistConfig, AppReducer)

})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = composeWithDevTools({
  // trace: true,
  // traceLimit: 25,
  actionsBlacklist: []
})

const middlewares = [thunk]
const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
)

const persistor = persistStore(store, {}, async () => {
  NetworkService.setupInterceptors(store)
  await store.dispatch(UserOperations.checkUserExist())
  store.dispatch(AppOperations.setLoading(false))
})

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { store, persistor }
