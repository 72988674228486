import AppActions from './app-actions'

const AppOperations = {
  setLoading: AppActions.setLoading,
  setSessionExpired: AppActions.setSessionExpired,
  setLoadingOverlay: AppActions.setLoadingOverlay,
  setSection: AppActions.setSection,
  setShowRateUsModal: AppActions.setShowRateUsModal,
  setShowRequestFeatureModal: AppActions.setShowRequestFeatureModal,
  setShowContactModal: AppActions.setShowContactModal,
  setShowInviteInvestorModal: AppActions.setShowInviteInvestorModal,
  setMessageTemplatesContainer: AppActions.setMessageTemplatesContainer
}

export default AppOperations
