import { RoutesLinks } from 'components/routes-links'
import { Container } from 'components/ui/objects/Container'
import Lang from 'lang'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import UserOperations from 'redux/user/user-operations'
import AuthService from 'services/auth-service'
import RegisterForm from './RegisterForm'

import BgLogin from 'assets/images/Login-vertical.png'
import Logo from 'assets/images/Logo-difree.svg'

const Register = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState(null)

  const { addToast } = useToasts()
  const [registered, setRegistered] = useState(false)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const setToken = (token) => dispatch(UserOperations.setToken({ token }))

  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }))
  const setLoginEntries = (loginEntries) =>
    dispatch(UserOperations.setLoginEntries(loginEntries))
  const setRefreshToken = (refreshToken) =>
    dispatch(UserOperations.setRefreshToken({ refreshToken }))

  const handleSubmit = async ({
    email,
    name,
    last_name,
    password_register,
    password_confirmation,
    accept_terms,
  }) => {
    try {
      setLoading(true)
      setEmail(email)
      let result = {}
      await AuthService.register({
          email,
          name,
          last_name,
          password: password_register,
          password_confirmation,
          accept_terms,
          language: Lang.getCurrentLang().code
      })

      if (Object.keys(result).length) {        
        setUserInfo(result.user)
        setToken(result.token)
        setLoginEntries(result.login_entries)
        setRefreshToken(result.refreshToken)
      }
      setRegistered(true)
    } catch (error) {
      console.error(error)
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  }

  if (registered) {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.REGISTER_RESEND_LINK,
          state: { email }
        }}
      />
    )
  } else {
    return (
      <Container variant="session">
        <Container>
          <RegisterForm
            onSubmit={handleSubmit}
            loading={loading}
          />
        </Container>
        <div className='hidden h-screen lg:block'>
          <div className='items-center justify-center hidden w-full h-screen bg-center bg-cover lg:flex' style={{ backgroundImage: `url(${BgLogin})` }}>
              <img src={Logo} className="max-w-[60%] max-h-1/2" alt="Logo" />
          </div>
        </div>
      </Container>
    )
  }
}

export default Register
