import axios from 'axios/index'
import Config from '../config'

const EvaluationsService = {

  getEvaluations: async () => {
    return axios.get(`${Config.DIFREE_API_URL}evaluations/`)
  },

  getEntrecompEvaluation: async () => {
    return axios.get(`${Config.DIFREE_API_URL}evaluations/entrecomp`)
  },

  startEntrecompEvaluation: async () => {
    return axios.post(`${Config.DIFREE_API_URL}evaluations/entrecomp`)
  },

  updateEntrecompEvaluation: async (data) => {
    return axios.patch(`${Config.DIFREE_API_URL}evaluations/entrecomp`, data)
  },

  submitEntrecompEvaluation: async () => {
    return axios.post(`${Config.DIFREE_API_URL}evaluations/entrecomp/submit`)
  },

  getDigcompEvaluation: async () => {
    return axios.get(`${Config.DIFREE_API_URL}evaluations/digcomp`)
  },

  startDigcompEvaluation: async () => {
    return axios.post(`${Config.DIFREE_API_URL}evaluations/digcomp`)
  },

  updateDigcompEvaluation: async (data) => {
    return axios.patch(`${Config.DIFREE_API_URL}evaluations/digcomp`, data)
  },

  submitDigcompEvaluation: async () => {
    return axios.post(`${Config.DIFREE_API_URL}evaluations/digcomp/submit`)
  }

}
export default EvaluationsService
