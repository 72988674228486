import { useTranslation } from 'react-i18next'
import { Input } from 'components/ui/atoms/Input'
import AccountService from 'services/account-service'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Loader } from 'components/ui/molecules/Loader'
import { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import moment from 'moment'
import { useLoginEntries } from 'hooks/useLoginEntries'
import { FaCircle } from 'react-icons/fa'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const Security = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const { loginEntries } = useLoginEntries()

  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(Yup.object().shape({
      password: Yup.string()
        .required(t('form_validation.required_field'))
        .min(8, t('form_validation.password_length')),
      new_password: Yup.string()
        .required(t('form_validation.required_field'))
        .min(8, t('form_validation.password_length'))
        .matches(/^.*(?=.*?[A-Z]).*$/, t('form_validation.password_req_uppercase'))
        .matches(/^.*(?=.*?[a-z]).*$/, t('form_validation.password_req_lowercase'))
        .matches(/^.*(?=.*?[0-9]).*$/, t('form_validation.password_req_number'))
        .matches(
          /^.*(?=.*?[\^ $ * . [ \] { } ( ) ? " ! @ # % & , > < ' : ; _ ~ ` \\ / |]).*$/,
          `${t('form_validation.password_req_special_char')}: ^ $ * . [ ] { } ( ) ? " ! @ # % & , > < ' : ; _ ~  \` \\ |`
        ),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('new_password'), null], t('form_validation.passwords_do_not_match'))
        .required(t('form_validation.required_field'))
    }))
  })

  const updatePassword = (values) => {
    setLoading(true)
    AccountService.updatePassword(values).then(() => {
      addToast(t('security.password_updated_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
    }).catch(error => {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('security.error_occurred_updating_password'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <>
      <div className='mb-6'>
        <h3 className={`border-b font-medium text-main-${Config.PLATFORM} font-bold mb-4 border-main-${Config.PLATFORM}`}>
          {t('security.update_password_section_title')}
        </h3>
        <form className="w-full" onSubmit={handleSubmit(updatePassword)}>
            <div className='w-full md:w-5/12'>
                <Input
                    reference={register}
                    type="password"
                    name="password"
                    required={true}
                    label={t('security.current_password_label')}
                    placeholder={t('security.current_password_placeholder')}
                    error={errors.password}
                />
                <Input
                    reference={register}
                    type="password"
                    name="new_password"
                    label={t('security.new_password_label')}
                    required={true}
                    placeholder={t('security.new_password_placeholder')}
                    error={errors.new_password}
                />
                <Input
                    reference={register}
                    type="password"
                    name="password_confirmation"
                    label={t('security.confirm_password_label')}
                    required={true}
                    placeholder={t('security.confirm_new_password_placeholder')}
                    error={errors.password_confirmation}
                />
            </div>
            <div className='flex justify-end '>
                <ButtonMain
                    width={'full lg:w-auto'}
                    text={t('buttons.update_password')}
                    type={'submit'}
                />
            </div>
        </form>
      </div>

      <div>
        <h3 className={`border-b font-medium text-main-${Config.PLATFORM} font-bold mb-4 border-main-${Config.PLATFORM}`}>
          {t('security.login_entries_section_title')}
        </h3>
        <div className="divide-y divide-gray-lines">
          {loginEntries.map((entry, index) => (
            <div key={index} className="flex py-2">
              <FaCircle className={`text-main-${Config.PLATFORM} mr-2`} />

              <div className="flex items-center text-xs">
                <div>
                  {entry.os} · {entry.browser} · {moment(entry.createdAt).format('DD/MM/YYYY HH:mm')}
                </div>
                {entry.city && <div>{entry.city}</div>}
              </div>
            </div>
          ))}
        </div >
      </div>

      {loading && <Loader />}
    </>
  )
}

export default Security
