import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import SidebarMenuItems from './sidebar-menu-items'
import { UiList } from 'components/ui/objects/UiList'
import Config from 'config'
import Logo from 'assets/images/Logo.png'
import { FaSignOutAlt, FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import UserOperations from 'redux/user/user-operations'

const Sidebar = ({ responsiveSidebarVisible, handleResponsiveSidebar }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const [hovers, setHovers] = useState({})
  const dispatch = useDispatch()

  const checkUrl = (item, url) => {
    let result = false
    if (item.url === url) {
      result = true
    } else if (item.section === 'reports' && url.includes('/reports')) {
      result = true
    } else if (item.section === 'evaluations' && (url.includes('/evaluations') || url.includes('score-cards'))) {
      result = true
    }

    return result
  }

  const onItemClick = (item, index) => {
    const tmpHovers = { ...hovers }
    Object.keys(tmpHovers).forEach((key, index) => {
      tmpHovers[key] = false
    })
    tmpHovers[index.toString()] = true
    setHovers(tmpHovers)
    history.push(item.url)
  }

  const onMouseOver = (index) => setHovers({ ...hovers, [index]: true })

  const onMouseLeave = (item, index) => {
    const tmpHovers = { ...hovers }
    Object.keys(tmpHovers).forEach((key) => (tmpHovers[key] = false))
    tmpHovers[index.toString()] = checkUrl(item, location.pathname)
    setHovers(tmpHovers)
  }

  const getMenuItems = () => {
    return SidebarMenuItems.filter(item => [undefined, null, responsiveSidebarVisible].includes(item.responsive)).map((item, index) => {
      return (
        <div id={`sidebar-menu-item-${index + 1}`} key={index} className="flex justify-center text-right w-full">
          <div
            onMouseOver={() => onMouseOver(index)}
            onMouseLeave={() => onMouseLeave(item, index)}
            onClick={() => onItemClick(item, index)}
            className={`flex items-center w-full  
              h-9 my-2 ml-6 lg:ml-2 px-2 
              rounded-l-2xl cursor-pointer text-center 
              hover:bg-white hover:text-main-${Config.PLATFORM} hover:shadow-none 
              ${checkUrl(item, location.pathname)
                ? `font-bold bg-white text-main-${Config.PLATFORM} shadow-none`
                : 'text-white'
              }`}
          >
            <div className="w-8 flex justify-between text-center text-white hover:text-white">
              {hovers[index] || checkUrl(item, location.pathname) ? item.iconHover : item.icon}
            </div>

            <div className="text-sm font-medium hidden lg:block">{t(item.title)}</div>
          </div>
        </div>
      )
    })
  }

  useEffect(() => {
    setHovers({})
  }, [location.pathname])

  if (responsiveSidebarVisible) {
    return (
      <div className={`fixed top-0 bg-main-${Config.PLATFORM} h-screen w-20 lg:w-52 z-20 block`}>
        <div className="menu-logo-container">
          <div className="h-12 px-2 flex justify-center items-center bg-white">
            <FaTimes onClick={() => handleResponsiveSidebar()} />
          </div>
        </div>
        <UiList className="mt-4 lg:mt-8">{getMenuItems()}</UiList>
        <div className="mt-8 fixed bottom-3 w-20 lg:w-52">
        <div className="flex justify-center text-center text-white border-b border-white mb-2 pb-4 cursor-pointer" onClick={() => dispatch(UserOperations.logoutUser())}>
            <FaSignOutAlt className='h-6 w-6'/>
          </div>
          <div className="text-xxs lg:text-xxs px-4 text-center text-white">
            {t('powered_by_kiota')}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`fixed top-0 bg-main-${Config.PLATFORM} h-screen w-20 lg:w-52 z-20 hidden sm:block`}>
        <div className="menu-logo-container">
          <div className="p-4 flex justify-center items-center hidden lg:flex bg-white">
            <img src={Logo} alt="Difree Logo" className="object-fit" />
          </div>
          <div className="h-12 px-2 flex justify-center items-center block lg:hidden bg-white">
            <img src={Logo} alt="Difree Logo" className="object-fit" />
          </div>
        </div>
        <UiList className="mt-4 lg:mt-8">{getMenuItems()}</UiList>
        <div className="mt-8 fixed bottom-3 w-20 lg:w-52">
          <div className="text-xxs lg:text-xxs px-4 text-center text-white">
            {t('powered_by_kiota')}
          </div>
        </div>
      </div>
    )
  }
}

export default Sidebar
