import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AccountService from '../../services/account-service'
import { useToasts } from 'react-toast-notifications'
import useUser from '../../hooks/useUser'
import { Loader } from '../ui/molecules/Loader'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Input } from '../ui/atoms/Input'
import Select from '../ui/atoms/Select'
import AcademicLevels from '../../assets/json/academic-levels.json'
import StudyAreas from '../../assets/json/study-areas.json'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const AcademicInfo = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { user, reloadUserInfo } = useUser()
  const [loading, setLoading] = useState(false)
  const [academicLevels, setAcademicLevels] = useState([])
  const [studyAreas, setStudyAreas] = useState([])



  const {
    register,
    handleSubmit,
    trigger,
    errors,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
        university: Yup.string().required(t('form_validation.required_field')),
        academic_degree: Yup.string().required(t('form_validation.required_field')),
        current_student: Yup.string().nullable(true),
      study_area: Yup.string().nullable(true),
      highest_academic_level: Yup.string().nullable(true)
    }), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
  }

  const updatePersonalInfo = (values) => {
    setLoading(true)
    if (values.current_student === 'false') {
        values.current_student = false
    } else if (values.current_student === 'true') {
        values.current_student = true
    }
    AccountService.updatePersonalInfo(values)
      .then(() => reloadUserInfo())
      .then(() => {
        addToast(t('academic_info.academic_info_updated_successfully'), {
          appearance: 'success',
          autoDismiss: true
        })
      })
      .catch(error => {
        addToast(
          error.response && error.response.data.msg
            ? error.response.data.msg
            : t('academic_info.error_occurred_updating_academic_info'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      }).finally(() => {
        setLoading(false)
      })
  }

  const onInvalid = () => {
    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
  }

  useEffect(() => {
    register('highest_academic_level')
    register('current_student')
    register('study_area')
  }, [register])

  useEffect(() => {
    if (user) {
      _setValue('university', user.university)
      _setValue('academic_degree', user.academic_degree)
      _setValue('highest_academic_level', user.highest_academic_level)
      _setValue('current_student', user.current_student)
      _setValue('study_area', user.study_area)
    }
  }, [user])

    useEffect(() => {
        setAcademicLevels(AcademicLevels.map(al => ({ id: al._id, value: t(`academic_levels.${al.key}`) })))
        setStudyAreas(StudyAreas.map(sa => ({ id: sa._id, value: t(`study_areas.${sa.key}`) })).sort((a, b) => {
            const nameA = a.value.toUpperCase();
            const nameB = b.value.toUpperCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            // names must be equal
            return 0;
        }))
    }, [])

  const YES_NO_OPTIONS = [
    { id: 'yes', value: t('yes') },
    { id: 'no', value: t('no') }
  ]

  return (
    <>
      <div className='flex flex-row justify-center w-full'>
        <div className="flex items-center w-full mt-4">
          <form
            className="flex flex-col w-full"
            onSubmit={handleSubmit(updatePersonalInfo, onInvalid)}
          >
            <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
                <Select
                    name="current_student"
                    label={t('academic_info.current_student_label')}
                    reference={register}
                    placeholder={t('academic_info.current_student_placeholder')}
                    items={YES_NO_OPTIONS}
                    onSelect={selection => _setValue('current_student', selection.length ? selection[0].id : null)}
                    initialValues={[true, false].includes(getValues().current_student) ? YES_NO_OPTIONS.filter((item) => getValues().current_student ? item.id === 'yes' : item.id === 'no') : []}
                />
                <Select
                    name="highest_academic_level"
                    label={t('academic_info.highest_academic_level_label')}
                    reference={register}
                    placeholder={t('academic_info.highest_academic_level_placeholder')}
                    items={academicLevels}
                    onSelect={selection => _setValue('highest_academic_level', selection.length ? selection[0].id : null)}
                    initialValues={academicLevels.filter(g => g.id === getValues().highest_academic_level)}
                />
            </div>

            <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
                <Select
                    name="study_area"
                    label={t('academic_info.study_area_label')}
                    reference={register}
                    placeholder={t('academic_info.study_area_placeholder')}
                    items={studyAreas}
                    onSelect={selection => _setValue('study_area', selection.length ? selection[0].id : null)}
                    initialValues={studyAreas.filter(g => g.id === getValues().study_area)}
                />
                <Input
                    reference={register}
                    name="university"
                    placeholder={t('academic_info.university_placeholder')}
                    label={t('academic_info.university_label')}
                    required={true}
                    error={errors.university}
                />
            </div>

            <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
                <Input
                    reference={register}
                    name="academic_degree"
                    placeholder={t('academic_info.academic_degree_placeholder')}
                    label={t('academic_info.academic_degree_label')}
                    required={true}
                    error={errors.academic_degree}
                />
            </div>
            <div className='flex justify-end mt-4'>
              <ButtonMain
                width={'full lg:w-auto'}
                text={t('academic_info.save_info_button')}
                type={'submit'}
              />
            </div>
          </form>
        </div>
      </div>

      {loading && <Loader />}
    </>
  )
}

export default AcademicInfo
