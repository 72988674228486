import AccountService from 'services/account-service'
import { useToasts } from 'react-toast-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import useUser from 'hooks/useUser'
import * as Yup from 'yup'

import { Autocomplete } from 'components/ui/atoms/Autocomplete'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Nationalities from 'assets/json/nationalities.json'
import { Loader } from 'components/ui/molecules/Loader'
import { TextArea } from 'components/ui/atoms/TextArea'
import Countries from 'assets/json/countries.json'
import { Input } from 'components/ui/atoms/Input'
import Select from 'components/ui/atoms/Select'
import Genders from 'assets/json/genders.json'
import FormUtils from 'utils/form-utils'

const PersonalInfo = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { user, reloadUserInfo } = useUser()
  const [loading, setLoading] = useState(false)
  const [genders, setGenders] = useState([])
  const [nationalities, setNationalities] = useState([])
  const [countries, setCountries] = useState([])

  const {
    register,
    handleSubmit,
    trigger,
    errors,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
      name: Yup.string().required(t('form_validation.required_field')),
      city: Yup.string().nullable(true),
      hobbies: Yup.string().nullable(true),
      zip_code: Yup.number().nullable(true).transform((_, val) => parseInt(val) === Number(val) ? parseInt(val) : null),
      last_name: Yup.string().required(t('form_validation.required_field')),
      email: Yup.string().email(t('form_validation.incorrect_format')).required(t('form_validation.required_field')),
      nationality: Yup.string().nullable(true),
      gender: Yup.string().nullable(true),
      date_of_birth: Yup.string()
        .nullable(true)
        .transform((value) => FormUtils.parseDateFromString(value, 'YYYY-MM-DD HH:mm'))
    }), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
  }

  const updatePersonalInfo = (values) => {
    setLoading(true)
    AccountService.updatePersonalInfo(values)
      .then(() => reloadUserInfo())
      .then(() => {
        addToast(t('personal_info.personal_info_updated_successfully'), {
          appearance: 'success',
          autoDismiss: true
        })
      })
      .catch(error => {
        addToast(
          error.response && error.response.data.msg
            ? error.response.data.msg
            : t('personal_info.error_occurred_updating_personal_info'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      }).finally(() => {
        setLoading(false)
      })
  }

  const onInvalid = () => {
    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
  }

  const getNationalityInitialValue = () => {
    const item = nationalities.find(n => n.id === getValues().nationality)

    if (item) {
      return item
    }

    return null
  }

  const getResidenceCountryInitialValue = () => {
    const item = countries.find(n => n.id === getValues().residence_country)

    if (item) {
      return item
    }

    return null
  }

  useEffect(() => {
    register('nationality')
    register('residence_country')
    register('gender')
  }, [register])

  useEffect(() => {
    if (user) {
      if (user.date_of_birth) {
        _setValue(
          'date_of_birth',
          FormUtils.getDateInputValueFromDate(new Date(user.date_of_birth))
        )
      }

      _setValue('name', user.name)
      _setValue('city', user.city)
      _setValue('residence_country', user.residence_country)
      if (user.zip_code) {
        _setValue('zip_code', user.zip_code)
      }
      _setValue('last_name', user.last_name)
      _setValue('email', user.email)
      _setValue('gender', user.gender)
      _setValue('nationality', user.nationality)
      _setValue('hobbies', user.hobbies || '')
    }
  }, [user])

  useEffect(() => {
    setGenders(Genders.map(g => ({ ...g, value: t(`genders.${g.value}`) })))
    setNationalities(Nationalities.map(n => ({ id: n._id, name: t(`nationalities.${n.key}`) })))
    setCountries(Countries.map(c => ({ id: c._id, name: t(`countries.${c.key}`) })))
  }, [])

  return (
    <>
      <div className='flex flex-row justify-center w-full'>
        <div className="flex items-center w-full mt-4">
          <form
            className="flex flex-col w-full"
            onSubmit={handleSubmit(updatePersonalInfo, onInvalid)}
          >
            <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
              <Input
                reference={register}
                name="name"
                placeholder={t('personal_info.name_placeholder')}
                label={t('personal_info.name_label')}
                required={true}
                error={errors.name}
              />
              <Input
                name="last_name"
                placeholder={t('personal_info.last_name_placeholder')}
                label={t('personal_info.last_name_label')}
                required={true}
                reference={register}
                error={errors.last_name}
              />
            </div>

            <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
              <Input
                name="email"
                placeholder={t('personal_info.email_placeholder')}
                label={t('personal_info.email_label')}
                required={true}
                reference={register}
                error={errors.email}
              />
              <Select
                name="gender"
                label={t('personal_info.gender_label')}
                reference={register}
                placeholder={t('personal_info.gender_placeholder')}
                items={genders}
                onSelect={selection => _setValue('gender', selection.length ? selection[0].id : null)}
                initialValues={getValues().gender ? [genders.find(g => g.id === getValues().gender)] : []}
              />
            </div>

            <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
              <Input
                type="date"
                name="date_of_birth"
                placeholder={t('personal_info.date_of_birth_placeholder')}
                label={t('personal_info.date_of_birth_label')}
                reference={register}
              />

              <Autocomplete
                name="nationality"
                label={t('personal_info.nationality_label')}
                placeholder={t('personal_info.nationality_placeholder')}
                options={nationalities}
                onSelect={selected => _setValue('nationality', selected?.id || null)}
                initialValues={getNationalityInitialValue()}
              />
            </div>
            <div className="grid grid-cols-1 gap-1 md:grid-cols-3">
            <Autocomplete
                name="residence_country"
                label={t('personal_info.residence_country_label')}
                placeholder={t('personal_info.residence_country_placeholder')}
                options={countries}
                onSelect={selected => _setValue('residence_country', selected?.id || null)}
                initialValues={getResidenceCountryInitialValue()}
              />
              <Input
                reference={register}
                name="city"
                placeholder={t('personal_info.city_placeholder')}
                label={t('personal_info.city_label')}
                required={false}
                error={errors.city}
              />
              <Input
                type={'number'}
                name="zip_code"
                placeholder={t('personal_info.zip_code_placeholder')}
                label={t('personal_info.zip_code_label')}
                required={false}
                reference={register}
                error={errors.zip_code}
              />
            </div>
            <div className="grid grid-cols-1 gap-1">
              <TextArea
                reference={register}
                name="hobbies"
                error={errors.hobbies}
                label={t('personal_info.hobbies_label')}
                maxLength={200}
                placeholder={t('personal_info.hobbies_placeholder')}
              />
            </div>
            <div className='flex justify-end mt-4'>
              <ButtonMain
                width={'full lg:w-auto'}
                text={t('buttons.save')}
                type={'submit'}
              />
            </div>
          </form>
        </div>
      </div>

      {loading && <Loader />}
    </>
  )
}

export default PersonalInfo
