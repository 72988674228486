import { RoutesLinks } from 'components/routes-links'
import { BottomText } from 'components/ui/atoms/BottomText'
import { FormTitle } from 'components/ui/atoms/FormTitle'
import { Loader } from 'components/ui/molecules/Loader'
import { Container } from 'components/ui/objects/Container'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'
import AuthService from 'services/auth-service'
import Plane from 'styles/images/plane.svg'
import Tick from 'styles/images/tick_blue.svg'
import moment from 'moment'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

import BgLogin from 'assets/images/Login-vertical.png'
import Logo from 'assets/images/Logo-difree.svg'

const RegisterResend = () => {
  const { t } = useTranslation()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  const registerMode = location.pathname.split('/')[2]
  const history = useHistory()
  const handleRedirect = (url) => history.push(url)
  const queryParams = new URLSearchParams(location.search)

  const email = history.location.state ? history.location.state.email : null
  const [emailSent, setEmailSent] = useState(false)

  const resendCode = async () => {
    try {
      setEmailSent(true)
      setError(null)
      setLoading(true)
      await AuthService.resendActivationCode(email)
    } catch (error) {
      setError(
        error.response && error.response.data.msg_key
          ? t(`resend_email_${error.response.data.msg_key}`)
          : t('error_occurred')
      )
      setEmailSent(false)
    } finally {
      setLoading(false)
    }
  }

  if (email) {
    return (
      <Container variant="session">
        <Container>
        <section className="flex flex-col items-center justify-start w-full h-full lg:justify-center">
          <div className="w-full px-8">
            <div className="flex items-center justify-center mt-8 lg:hidden">
              <img src={Logo} alt="Difree Logo" className="h-20 object-fit" />
            </div>
              <div className="flex flex-col">
                <>
                  <FormTitle
                    formTitle={t('register_resend.title')}
                    formSubtitle={t('register_resend.subtitle')}
                  />
                  {!error && (
                    <div className="flex justify-center w-full py-6">
                      <img
                        src={Plane}
                        alt="Paper plane"
                        className="w-20 sm:w-auto"
                      />
                    </div>
                  )}
                  {!error && (
                    <div className="text-sm text-justify text-black">
                      <p className="py-2">
                        {t('register_resend.description_first_part')}
                        <span className="font-medium">{email}</span>
                        {t('register_resend.description_second_part')} {t('register_resend.await')}
                      </p>
                      <p className="py-2">{t('register_resend.description_error')}</p>
                    </div>
                  )}
                  {error && (
                    <div className="my-4 text-sm text-black">
                      <p className="py-2">{t(error)}</p>
                    </div>
                  )}
                  {!error && <div className='my-4'>
                    <ButtonMain
                      type={'submit'}
                      width={'full'}
                      paddingVertical={3}
                      onClick={resendCode}
                      text={t('register_resend.resend_button')}
                    />
                    {emailSent && (
                      <div className="flex justify-center w-full mt-2 text-sm text-gray">
                        <img src={Tick} alt="Tick icon" className="w-3 mr-2" />
                        {t('register_resend.msg_resend_successfully')}
                      </div>
                    )}
                  </div>}
                  <div className="w-full text-center">
                    <Link
                      to={`${RoutesLinks.LOGIN_LINK}`}
                      className={`underline text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}
                    >
                      {t('register_resend.navigate_to_login_link')}
                    </Link>
                  </div>
                </>
                {loading && <Loader />}
              </div>
            </div>
            <BottomText text={t('copyright_rights', { year: moment().format('YYYY') }) }/>
          </section>
        </Container>

        <div className='hidden lg:block'>
          <div className='items-center justify-center hidden w-full h-screen bg-center bg-cover lg:flex' style={{ backgroundImage: `url(${BgLogin})` }}>
              <img src={Logo} className="max-w-[60%] max-h-1/2" alt="Logo" />
          </div>
        </div>
      </Container>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.LOGIN_LINK
        }}
      />
    )
  }
}

export default RegisterResend
