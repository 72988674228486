import Config from "config";

export function FormTitle(props) {
  return (
    <div className="block mt-10 mb-4 sm:mb-12 sm:px-0 lg:mt-0 form_title_align">
      <h1 className={`text-main-${Config.PLATFORM}`}>{props.formTitle}</h1>
      <h5 className={`text-secondary-${Config.PLATFORM} font-semibold`}>{props.formSubtitle}</h5>
    </div>
  );
}
