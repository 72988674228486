export const RoutesLinks = {
  LOGIN_LINK: '/login',
  ACTIVATE_LINK: '/activate',
  REGISTER_LINK: '/register/',
  REGISTER_RESEND_LINK: '/register/resend',
  REGISTER_CONFIRM: '/register/confirm',
  FORGOT_LINK: '/forgot',
  FORGOT_RESEND_LINK: '/forgot/resend',
  FORGOT_CONFIRM_LINK: '/forgot/confirm',
  ACCOUNT_LINK: '/account',
  REPORTS_LINK: '/reports',
  EVALUATIONS_LINK: '/evaluations'
}
