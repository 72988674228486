import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import Config from 'config'
import EntreCompQuestions from './EntreCompQuestions.json'
import DigCompQuestions from './DigCompQuestions.json'

import { FaArrowRight, FaCheckCircle, FaChevronCircleDown, FaChevronCircleUp, FaExclamationCircle, FaMinus } from 'react-icons/fa'
import { AiOutlineExclamationCircle } from 'react-icons/ai'

const EvaluationProgressSection = ({
  onClose,
  questions,
  setCurrentIndex,
  evaluation
}) => {

  const evaluationQuestions = {
    entrecomp: EntreCompQuestions,
    digcomp: DigCompQuestions
  }

  const { t } = useTranslation()

  const [sections, setSections] = useState([])
  const [subsections, setSubsections] = useState({})

  useEffect(() => {
    const _sections = []
    const _subsections = {}
    evaluationQuestions[evaluation].forEach((question) => {
      if (!_sections.includes(question.section)) {
        _sections.push(question.section)
        _subsections[question.section] = evaluationQuestions[evaluation].filter((q) => q.section === question.section).map((q) => q.subsection)
      }
    })

    setSections(_sections)
    setSubsections(_subsections)
  }, [evaluation])


  const [collapsed, setCollapsed] = useState({})
  const expandOrCollapse = (elementId) => {
    const element = document.getElementById(elementId)
    element.classList.toggle('hidden')
    setCollapsed({ ...collapsed, [elementId]: !collapsed[elementId] })
  }

  const moveToQuestion = (section, subsection) => {
    setCurrentIndex(evaluationQuestions[evaluation].findIndex((q) => q.section === section && q.subsection === subsection))
  }

  return (
    <SidePanel title={t('evaluations.evaluation_progress')} onClose={() => onClose && onClose()} width={'2/5'}>
      {sections.map((section, index) => {
        return (
          <div key={section} className='w-full'>
            <div className={`p-2 flex justify-between bg-main-${Config.PLATFORM} cursor-pointer ${index === 0 && 'rounded-t-lg'} ${index === sections.length - 1 && collapsed[`${section}_subsections`] && 'rounded-b-lg'}`} onClick={() => expandOrCollapse(`${section}_subsections`)}>
                <div className={`flex items-center text-white`}>
                    <FaExclamationCircle className={`relative inline-block mr-2 text-white`} /> {t(`${evaluation}_sections.${section}`)}
                </div>
                <div>
                    {collapsed[`${section}_subsections`] ? <FaChevronCircleDown
                        data-panel={`${section}_subsections`}
                        className={'text-white inline-block'}
                      /> : <FaChevronCircleUp
                      data-panel={`${section}_subsections`}
                      className={'text-white inline-block'}
                    />}
                </div>
            </div>
            <div id={`${section}_subsections`} className={`bg-white ${index === sections.length - 1 && 'rounded-b-lg'}`}>
              {subsections[section].map((subsection, index) => {
                return (
                  <div key={subsection} className={`p-2 flex justify-between cursor-pointer hover:bg-secondary-${Config.PLATFORM}`} onClick={() => moveToQuestion(section, subsection)}>
                    <div className='flex items-center px-4'>
                        {questions.find((q) => q.section === section && q.subsection === subsection && q.answer !== null) ? <FaCheckCircle className='relative inline-block w-5 h-5 mr-2 fill-svg' /> : <AiOutlineExclamationCircle className='relative inline-block w-5 h-5 mr-2 fill-svg' />} {t(`${evaluation}_subsections.${subsection}`)}
                    </div>
                    <div>
                      <FaArrowRight
                          className={'inline-block'}
                        />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </SidePanel>
  )
}

export default EvaluationProgressSection
