import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { RoutesLinks } from 'components/routes-links'
import { FormTitle } from 'components/ui/atoms/FormTitle'
import { BottomText } from 'components/ui/atoms/BottomText'
import Tick from 'styles/images/tick_blue.svg'
import moment from 'moment'
import Logo from 'assets/images/Logo.png'

import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const ForgotPasswordResendForm = ({ onSubmit, error, emailSent }) => {
  const { t } = useTranslation()

  return (
    <section className="flex flex-col justify-center items-center h-full w-full">
      <div className="md:w-full px-10">
        <div className="flex justify-center items-center block lg:hidden mt-10">
          <img src={Logo} alt="Difree Logo" className="object-fit" />
        </div>
        <div className="flex flex-col">
          <>
            <FormTitle formTitle={t('forgot_password_resend.title')} />
            <div className="text-black text-sm">
              <p className="pb-2">
                {!error
                  ? t('forgot_password_resend.recover_password_code_sent')
                  : t('forgot_password_resend.recover_password_code_failed')}
              </p>
            </div>

            <div className="w-full flex flex-col justify-center my-6 sm:my-14">
              <ButtonMain
                onClick={onSubmit}
                text={t('forgot_password_resend.resend_code_button')}
              />
              {emailSent && (
                <div className="text-gray flex text-sm w-full justify-center mt-4">
                  <img src={Tick} alt="Tick icon" className="w-3 mr-2" />
                  {t('forgot_password_resend.msg_resend_successfully')}
                </div>
              )}
            </div>

            <div className="w-full text-center mt-4">
              <Link to={`${RoutesLinks.LOGIN_LINK}`} className="underline">
                {t('forgot_password_resend.navigate_to_login_link')}
              </Link>
            </div>
          </>
        </div>
      </div>
      <BottomText text={t('copyright_rights', { year: moment().format('YYYY') }) }/>

    </section>
  )
}

export default ForgotPasswordResendForm
