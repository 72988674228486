import { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { Container } from 'components/ui/objects/Container'
import { RoutesLinks } from 'components/routes-links'
import LoginForm from './LoginForm'
import AuthService from 'services/auth-service'
import Lang from 'lang'
import { useAuth } from 'hooks/useAuth'
import useUser from 'hooks/useUser'
import { useLoginEntries } from 'hooks/useLoginEntries'

import BgLogin from 'assets/images/Login-vertical.png'
import Logo from 'assets/images/Logo-difree.svg'

const Login = () => {
  const { token, setToken, setRefreshToken } = useAuth()
  const { setUserInfo } = useUser()
  const { setLoginEntries } = useLoginEntries()
  const { addToast } = useToasts()
  const { i18n, t } = useTranslation()
  const [loading, setLoading] = useState(false)


  const onSubmit = async ({ email, password, keep_logged }) => {
    try {
      setLoading(true)
      // const result = await AuthService.login(email, password, keep_logged);
      const result = await AuthService.login(email, password, true)
      Lang.setLang(result.data.user.preferences.language.code)
      await i18n.changeLanguage(result.data.user.preferences.language.code)

      setUserInfo(result.data.user)
      setToken(result.data.token)
      setLoginEntries(result.data.login_entries)
      setRefreshToken(result.data.refreshToken)
    } catch (error) {
      console.error(error)
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('login.error_occurred_login_in'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  }

  if (token) {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.EVALUATIONS_LINK
        }}
      />
    )
  }

  return (
    <Container variant="session">
      <Container>
        <LoginForm
          activeTheme={'light'}
          onSubmit={onSubmit}
          loading={loading}
        />
      </Container>
      <div className='hidden lg:block'>
        <div className='items-center justify-center hidden w-full h-screen bg-center bg-cover lg:flex' style={{ backgroundImage: `url(${BgLogin})` }}>
            <img src={Logo} className="max-w-[60%] max-h-1/2" alt="Logo" />
        </div>
      </div>
    </Container>
  )
}

export default Login
