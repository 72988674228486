export const AppActionsTypes = {
  SET_LOADING: 'app/SET_LOADING',
  SET_SESSION_EXPIRED: 'app/SET_SESSION_EXPIRED',
  SET_LOADING_OVERLAY: 'app/SET_LOADING_OVERLAY',
  SET_SECTION: 'app/SET_SECTION',
  SET_SHOW_RATE_US_MODAL: 'app/SET_SHOW_RATE_US_MODAL',
  SET_SHOW_REQUEST_FEATURE_MODAL: 'app/SET_SHOW_REQUEST_FEATURE_MODAL',
  SET_SHOW_CONTACT_MODAL: 'app/SET_SHOW_CONTACT_MODAL',
  SET_SHOW_INVITE_INVESTOR_MODAL: 'app/SET_SHOW_INVITE_INVESTOR_MODAL',
  SET_MESSAGE_TEMPLATES_CONTAINER: 'app/SET_MESSAGE_TEMPLATES_CONTAINER'
}
