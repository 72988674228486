import { useDispatch, useSelector } from 'react-redux'
import AccountService from 'services/account-service'
import UserOperations from '../redux/user/user-operations'
import { useEffect, useState } from 'react'
import { get } from 'lodash'

const useUser = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [userLanguage, setUserLanguage] = useState('en')
  const [isDiFree, setIsDiFree] = useState(false)

  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }))

  useEffect(() => {
    setUserLanguage(get(user, 'preferences.language.code') || 'en')
    setIsDiFree(user?.role === 'difree')
  }, [user])

  return {
    user,
    userLanguage,
    isDiFree,
    setUserInfo,

    reloadUserInfo: () => {
      return AccountService.getUser().then(({ data }) => setUserInfo(data))
    },

    getUserFullName: () => (user?.last_name ? `${user?.name} ${user?.last_name}` : `${user?.name}`) || ''
  }
}

export default useUser
