import ReactDOM from 'react-dom'
import './spinner.css'

export function Loader () {
  return ReactDOM.createPortal(
    <div
      className="bg-gray-opacity h-screen fixed top-0 right-0 z-50"
      id="spinner"
    >
      <div className="spinner-container">
        <div className="loading-spinner">
        </div>
      </div>
    </div>
    , document.body)
}
