import { useTranslation } from "react-i18next"

export function BottomText (props) {
  const { t } = useTranslation()

  return (
    <>
      <div className="sm:w-3/4 bottom-0 | pt-8 pb-4 | text-center">
        <span className="block left-0 | text-gray font-normal text-xs">{props.text}</span>
      </div>
      <div className="sm:w-3/4 bottom-0 | text-center border-gray-lines cursor-pointer hover:underline">
        <a href="https://www.kiota.com" target="_blank" className="block left-0 | text-gray font-normal text-xxs" rel="noreferrer">{t('powered_by_kiota')}</a>
      </div>
    </>
  )
}
