import axios from 'axios/index'
import Config from '../config'

const AccountService = {
  getUser: async () => {
    return axios.get(`${Config.DIFREE_API_URL}account/personal-info`)
  },

  updatePersonalInfo: async (data) => {
    return axios.patch(`${Config.DIFREE_API_URL}account/personal-info`, data)
  },

  updatePassword: async (values) => {
    return axios.patch(`${Config.DIFREE_API_URL}account/password`, values)
  },

  updatePreferences: async (data) => {
    return axios.patch(`${Config.DIFREE_API_URL}account/preferences`, data)
  }
}
export default AccountService
