import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import moment from 'moment'
import * as Yup from 'yup'
import Config from 'config'
import { BottomText } from 'components/ui/atoms/BottomText'
import { FormTitle } from 'components/ui/atoms/FormTitle'
import { RoutesLinks } from 'components/routes-links'
import { Input } from 'components/ui/atoms/Input'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Logo from 'assets/images/Logo-difree.svg'

const ForgotPasswordForm = ({ onSubmit }) => {
  const { t } = useTranslation()

  const ForgotSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form_validation.incorrect_format'))
      .required(t('form_validation.required_field'))
  })

  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ForgotSchema)
  })

  return (
    <section className="flex flex-col items-center justify-start w-full h-full lg:justify-center">
      <div className="px-8 md:w-full">
        <div className="flex items-center justify-center mt-8 lg:hidden">
          <img src={Logo} alt="Difree Logo" className="h-20 object-fit" />
        </div>
        <div className="flex flex-col">
          <FormTitle formTitle={t('forgot_password.title')} />
          <div className="text-black">
            <p className="pb-2 text-sm">{t('forgot_password.recover_instructions')}</p>
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4">
              <Input
                reference={register}
                id="email"
                type="email"
                name="email"
                required={true}
                label={t('forgot_password.email_label')}
                placeholder={t('forgot_password.email_placeholder')}
                error={errors.email}
              />
            </div>
            <div className='my-4'>
              <ButtonMain
                type={'submit'}
                width={'full'}
                paddingVertical={3}
                text={t('forgot_password.forgot_password_button')}
              />
            </div>
          </form>
          <div className="w-full mt-4 text-xs text-center">
            <Link to={`${RoutesLinks.LOGIN_LINK}`} className={`underline text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}>
              {t('forgot_password.navigate_to_login_link')}
            </Link>
          </div>
        </div>
      </div>
      <BottomText text={t('copyright_rights', { year: moment().format('YYYY') }) }/>
    </section>
  )
}

export default ForgotPasswordForm
