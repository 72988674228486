/* eslint-disable default-case */
import produce from 'immer'
import { AppActionsTypes } from './app-actions-types'

const { SET_LOADING } = AppActionsTypes
const { SET_LOADING_OVERLAY } = AppActionsTypes
const { SET_SECTION } = AppActionsTypes
const { SET_SHOW_RATE_US_MODAL } = AppActionsTypes
const { SET_SHOW_REQUEST_FEATURE_MODAL } = AppActionsTypes
const { SET_SHOW_CONTACT_MODAL } = AppActionsTypes
const { SET_MESSAGE_TEMPLATES_CONTAINER } = AppActionsTypes
const { SET_SHOW_INVITE_INVESTOR_MODAL } = AppActionsTypes
const { SET_SESSION_EXPIRED } = AppActionsTypes

const defaultState = {
  loading: true,
  session_expired: false,
  loadingOverlay: {
    state: false,
    message: ''
  },
  section: '',
  showRateUsModal: false,
  showRequestFeatureModal: false,
  showContactModal: false,
  showInviteInvestorModal: false,
  messageTemplatesContainer: { show: false, meta: {}, editMode: false }
}

const AppReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_LOADING:
        draft.loading = action.payload.status
        break
      case SET_LOADING_OVERLAY:
        draft.loadingOverlay = action.payload.status
        break
      case SET_SECTION:
        draft.section = action.payload.section
        break
      case SET_SHOW_RATE_US_MODAL:
        draft.showRateUsModal = action.payload.showRateUsModal
        break
      case SET_SHOW_REQUEST_FEATURE_MODAL:
        draft.showRequestFeatureModal = action.payload.showRequestFeatureModal
        break

      case SET_SHOW_CONTACT_MODAL:
        draft.showContactModal = action.payload.showContactModal
        break

      case SET_SHOW_INVITE_INVESTOR_MODAL:
        draft.showInviteInvestorModal = action.payload.showInviteInvestorModal
        break
      case SET_SESSION_EXPIRED:
        draft.session_expired = action.payload.status
        break
      case SET_MESSAGE_TEMPLATES_CONTAINER:
        draft.messageTemplatesContainer = action.payload.messageTemplatesContainer
        break
    }
  })

export default AppReducer
