import axios from 'axios/index'
import Config from '../config'

const AuthService = {
  confirmRegister: async (code) => {
    return axios.post(`${Config.DIFREE_API_URL}auth/register/confirm`, {
      code
    })
  },

  login: async (email, password, keepLogged) => {
    return axios.post(`${Config.DIFREE_API_URL}auth/login`, {
      email,
      password,
      keep_logged: keepLogged
    })
  },

  register: async (body) => {
    return axios.post(`${Config.DIFREE_API_URL}auth/register`, body)
  },

  resendActivationCode: async (email) => {
    return axios.post(`${Config.DIFREE_API_URL}auth/register/resend`, {
      email
    })
  },

  forgotPassword: async (email) => {
    return axios.post(`${Config.DIFREE_API_URL}auth/forgot-password`, {
      email
    })
  },

  checkRestorePasswordCode: async (code) => {
    return axios.get(
      `${Config.DIFREE_API_URL}auth/forgot-password/checks`,
      {
        params: { code }
      }
    )
  },

  restorePassword: async (code, password, passwordConfirmation) => {
    return axios.post(`${Config.DIFREE_API_URL}auth/restore-password`, {
      code,
      password,
      password_confirmation: passwordConfirmation
    })
  }
}
export default AuthService
