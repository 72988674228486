import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa'
import Config from 'config'

const PasswordRequirements = ({ errors }) => {
  const { t } = useTranslation()
  let errArr = []

  if (
    (errors.password_register && errors.password_register.types) ||
    (errors.new_password && errors.new_password.types)
  ) {
    if (errors.password_register && errors.password_register.types.matches) {
      errArr = errArr + errors.password_register.types.matches
    } else if (errors.new_password && errors.new_password.types.matches) {
      errArr = errArr + errors.new_password.types.matches
    }
    if (errors.password_register && errors.password_register.types.min) {
      errArr = errArr + errors.password_register.types.min
    } else if (errors.new_password && errors.new_password.types.min) {
      errArr = errArr + errors.new_password.types.min
    }
  }

  return (
    <div className="text-xs mb-4 -mt-4">
      {t('password_requirements.title')}
      <ul className="ml-2">
        <li>
          {errArr && !errArr.includes(t('password_requirements.min_length'))
            ? (
            <FaCheck className={`mr-1 text-main-${Config.PLATFORM} inline`}/>
              )
            : (
            <FaCheck className="mr-1 text-red inline" />
              )}
          {t('password_requirements.min_char')}{' '}
        </li>
        <li>
          {errArr && !errArr.includes(t('password_requirements.uppercase'))
            ? (
                <FaCheck className={`mr-1 text-main-${Config.PLATFORM} inline`}/>
            )
            : (
                <FaCheck className="mr-1 text-red inline" />
                )}
          {t('password_requirements.uppercase')}{' '}
        </li>
        <li>
          {errArr && !errArr.includes(t('password_requirements.lowercase'))
            ? (
                <FaCheck className={`mr-1 text-main-${Config.PLATFORM} inline`}/>
            )
            : (
                <FaCheck className="mr-1 text-red inline" />
                )}
          {t('password_requirements.lowercase')}{' '}
        </li>
        <li>
          {errArr && !errArr.includes(t('password_requirements.number'))
            ? (
                <FaCheck className={`mr-1 text-main-${Config.PLATFORM} inline`}/>
              )
            : (
                <FaCheck className="mr-1 text-red inline" />
                )}
          {t('password_requirements.number')}{' '}
        </li>
        <li>
          {errArr && !errArr.includes(t('password_requirements.special_char'))
            ? (
                <FaCheck className={`mr-1 text-main-${Config.PLATFORM} inline`}/>
              )
            : (
                <FaCheck className="mr-1 text-red inline" />
                )}
          {`${t('password_requirements.special_char')}: ^ $ * . [ ] { } ( ) ? " ! @ # % & , > < ' : ; _ ~  \` \\ |`}
        </li>
      </ul>
    </div>
  )
}

export default PasswordRequirements
