import { useSelector, useDispatch } from 'react-redux'
import UserOperations from '../redux/user/user-operations'

export const useAuth = () => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const refreshToken = useSelector((state) => state.auth.refreshToken)
  const setToken = (token) => dispatch(UserOperations.setToken({ token }))
  const setRefreshToken = (refreshToken) => dispatch(UserOperations.setRefreshToken({ refreshToken }))

  return { token, refreshToken, setToken, setRefreshToken}
}
