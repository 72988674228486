import { RoutesLinks } from 'components/routes-links'
import { Loader } from 'components/ui/molecules/Loader'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import AuthService from 'services/auth-service'
import { useAuth } from 'hooks/useAuth'
import useUser from 'hooks/useUser'
import { useLoginEntries } from 'hooks/useLoginEntries'

const RegisterConfirm = () => {
  const { token, setToken, setRefreshToken } = useAuth()
  const { setUserInfo } = useUser()
  const { setLoginEntries } = useLoginEntries()
  const location = useLocation()
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const { addToast } = useToasts()
  const queryParams = new URLSearchParams(location.search)
  const code = queryParams.get('code')

    useEffect(() => {
    const confirmRegister = async () => {
      try {
        const { data } = await AuthService.confirmRegister(code)

        setUserInfo(data.user)
        setToken(data.token)
        setLoginEntries(data.login_entries)
        setRefreshToken(data.refreshToken)
      } catch (error) {
        console.error(error)
        setError(true)
        addToast(
          error.response && error.response.data.msg_key
            ? t(`register_confirm.${error.response.data.msg_key}`)
            : error.response.status === 400 ? t('register_confirm.invalid_code') : t('error_occurred_confirming_register'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      }
    }

    if (code) {
      confirmRegister()
    }
  }, [code])

  if (!code || error) {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.LOGIN_LINK
        }}
      />
    )
  } else if (token) {
    const { from } = location.state || {
      from: { pathname: RoutesLinks.REGISTER_CONFIRM }
    }

    return (
      <Redirect
        to={{
          pathname: RoutesLinks.EVALUATIONS_LINK,
          state: { from }
        }}
      />
    )
  } else {
    return <Loader />
  }
}

export default RegisterConfirm
