import { Route, Switch, Redirect } from 'react-router-dom'
import Reports from './reports/Reports'
import RegisterConfirm from './register/RegisterConfirm'
import RegisterResend from './register/RegisterResend'
import Evaluations from './evaluations/Evaluations'
import ForgotPasswordConfirm from './forgot-password/ForgotPasswordConfirm'
import { RoutesLinks } from './routes-links'
import { useAuth } from '../hooks/useAuth'
import ForgotPasswordResend from './forgot-password/ForgotPasswordResend'
import PrivateRoute from './private-route'
import Entrecomp from './evaluations/EntreComp'
import DigComp from './evaluations/DigComp'
import Account from './account/Account'
import Forgot from './forgot-password/ForgotPassword'
import Login from './login/Login'
import Register from './register/Register'

const Routes = () => {
  const { token } = useAuth()

  return (
    <Switch>
      <PrivateRoute exact path={RoutesLinks.ACCOUNT_LINK}>
        <Account />
      </PrivateRoute>

      <PrivateRoute exact path={`${RoutesLinks.REPORTS_LINK}`}>
        <Reports />
      </PrivateRoute>

      <PrivateRoute exact path={`${RoutesLinks.EVALUATIONS_LINK}`}>
        <Evaluations />
      </PrivateRoute>

      <PrivateRoute exact path={`${RoutesLinks.EVALUATIONS_LINK}/entrecomp`}>
        <Entrecomp />
      </PrivateRoute>

      <PrivateRoute exact path={`${RoutesLinks.EVALUATIONS_LINK}/digcomp`}>
        <DigComp />
      </PrivateRoute>

      <Route exact path={RoutesLinks.LOGIN_LINK}>
        <Login />
      </Route>

      {!token && (
        <Route exact path={RoutesLinks.REGISTER_LINK}>
          <Register/>
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.REGISTER_RESEND_LINK}>
          <RegisterResend />
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.REGISTER_CONFIRM}>
          <RegisterConfirm />
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.FORGOT_LINK}>
          <Forgot />
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.FORGOT_RESEND_LINK}>
          <ForgotPasswordResend />
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.FORGOT_CONFIRM_LINK}>
          <ForgotPasswordConfirm />
        </Route>
      )}

    <Redirect to={RoutesLinks.EVALUATIONS_LINK} />

    </Switch>
  )
}

export default Routes
