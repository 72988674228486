import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ForgotPasswordConfirmForm from './ForgotPasswordConfirmForm'
import { Container } from 'components/ui/objects/Container'
import { useToasts } from 'react-toast-notifications'
import { RoutesLinks } from 'components/routes-links'
import AuthService from 'services/auth-service'
import Lang from 'lang'
import { Loader } from 'components/ui/molecules/Loader'
import { useAuth } from '../../hooks/useAuth'
import useUser from '../../hooks/useUser'
import { useLoginEntries } from '../../hooks/useLoginEntries'
import Logo from 'assets/images/Logo-difree.svg'
import BgLogin from 'assets/images/Login-vertical.png'

const ForgotPasswordConfirm = () => {
  const location = useLocation()
  const { i18n, t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const { token, setToken, setRefreshToken } = useAuth()
  const { setUserInfo } = useUser()
  const { setLoginEntries } = useLoginEntries()
  const [loading, setLoading] = useState(true)
  const queryParams = new URLSearchParams(location.search)
  const recoverCode = queryParams.get('code')

  useEffect(() => {
    const checkRestorePasswordCode = async () => {
      try {
        setLoading(true)
        await AuthService.checkRestorePasswordCode(recoverCode)
      } catch (error) {
        addToast(
          error.response && error.response.data.msg_key
            ? t(error.response.data.msg_key)
            : error.response.status === 400 ? t('forgot_password_confirm.invalid_confirmation_code') : t('error_occurred'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
        history.push(RoutesLinks.LOGIN_LINK)
      } finally {
        setLoading(false)
      }
    }

    if (recoverCode) {
      checkRestorePasswordCode()
    } else {
      addToast(t('invalid_code'), {
        appearance: 'error',
        autoDismiss: true
      })
      history.push(RoutesLinks.LOGIN_LINK)
    }
  }, [recoverCode])

  const onSubmit = async ({ new_password, password_confirmation }) => {
    try {
      setLoading(true)
      const result = await AuthService.restorePassword(
        recoverCode,
        new_password,
        password_confirmation
      )
      Lang.setLang(result.data.user.preferences.language.code)
      await i18n.changeLanguage(result.data.user.preferences.language.code)

      setUserInfo(result.data.user)
      setToken(result.data.token)
      setLoginEntries(result.data.login_entries)
      setRefreshToken(result.data.refreshToken)
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  }

  if (token) {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.EVALUATIONS_LINK
        }}
      />
    )
  } else {
    return (
      <Container variant="session">
        {loading && <Loader />}
        <Container>
          <ForgotPasswordConfirmForm onSubmit={onSubmit} />
        </Container>
        <div className='hidden lg:block'>
          <div className='items-center justify-center hidden w-full h-screen bg-center bg-cover lg:flex' style={{ backgroundImage: `url(${BgLogin})` }}>
              <img src={Logo} className="max-w-[60%] max-h-1/2" alt="Logo" />
          </div>
        </div>
      </Container>
    )
  }
}

export default ForgotPasswordConfirm
