import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Logo from "assets/images/Logo-difree.svg";

import Config from "config";
import { Checkbox } from "components/ui/molecules/Checkbox";
import { BottomText } from "components/ui/atoms/BottomText";
import { FormTitle } from "components/ui/atoms/FormTitle";
import ShowPassword from "assets/images/ShowPassword.png";
import { RoutesLinks } from "components/routes-links";
import { Input } from "components/ui/atoms/Input";
import moment from "moment";
import Parameters from "config/parameters.json";
import { ButtonMain } from "components/ui/atoms/ButtonMain";

const RegisterForm = ({ onSubmit, loading }) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleShowhide = () => {
    setShowPassword(!showPassword);
  };

  const handleShowhideConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email(t("form_validation.incorrect_format")).required(t("form_validation.required_field")),
    name: Yup.string().required(t("form_validation.required_field")),
    last_name: Yup.string().required(t("form_validation.required_field")),
    company_name: Yup.string().required(t("form_validation.required_field")),
    password_register: Yup.string()
      .required(t("form_validation.required_field"))
      .min(8, t("password_requirements.min_length"))
      .matches(/^.*(?=.*?[A-Z]).*$/, t("password_requirements.uppercase"))
      .matches(/^.*(?=.*?[a-z]).*$/, t("password_requirements.lowercase"))
      .matches(/^.*(?=.*?[0-9]).*$/, t("password_requirements.number"))
      .matches(
        /^.*(?=.*?[\^ $ * . [ \] { } ( ) ? " ! @ # % & , > < ' : ; _ ~ ` \\ / |]).*$/,
        t("password_requirements.special_char")
      ),

    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password_register"), null], t("form_validation.passwords_do_not_match"))
      .required(t("form_validation.required_field")),

    accept_terms: Yup.boolean().oneOf([true], t("form_validation.required_field")),
  });

  const { register, handleSubmit, errors, setValue, trigger, getValues } = useForm({
    resolver: yupResolver(RegisterSchema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  return (
    <section className={`flex flex-col items-center justify-center w-full h-screen lg:justify-center`}>
      <div className={`w-full px-8 overflow-y-auto `}>
        <div className="flex items-center justify-center mt-8 lg:hidden">
          <img src={Logo} alt="Difree Logo" className="h-20 object-fit" />
        </div>
        <FormTitle formTitle={t("register_form.title")} formSubtitle={t("register_form.subtitle")} />

        <form className="w-full">
          <Input
            reference={register}
            id="email"
            name="email"
            type="email"
            label={t("register_form.email_label") + ": *"}
            placeholder={t("register_form.email_placeholder")}
            error={errors.email}
          />
          <div className="mt-4">
            <Input
              reference={register}
              id="name"
              name="name"
              type="text"
              label={t("register_form.first_name_label") + ": *"}
              placeholder={t("register_form.first_name_placeholder")}
              error={errors.name}
            />
          </div>

          <div className="mt-4">
            <Input
              reference={register}
              id="last_name"
              name="last_name"
              type="text"
              label={t("register_form.last_name_label") + ": *"}
              placeholder={t("register_form.last_name_placeholder")}
              error={errors.last_name}
            />
          </div>
          <div className="mt-4">
            <Input
              reference={register}
              id="passwordRegister"
              name="password_register"
              type={showPassword ? "text" : "password"}
              label={t("register_form.password_label") + ": *"}
              placeholder={t("register_form.password_placeholder")}
              error={errors}
              icon={ShowPassword}
              show={handleShowhide}
              isRegister="isRegister"
              disabled={loading}
            />
          </div>
          <div className="mt-2">
            <Input
              disabled={loading}
              reference={register}
              id="confirm_password"
              name="password_confirmation"
              type={showConfirm ? "text" : "password"}
              label={t("register_form.confirm_password_label") + ": *"}
              placeholder={t("register_form.confirm_password_placeholder")}
              error={errors.password_confirmation}
              icon={ShowPassword}
              show={handleShowhideConfirm}
            />
          </div>
          <Checkbox
            id="accept_terms"
            label={
              <a
                href={Parameters.terms_and_conditions[Config.PLATFORM]}
                target="_blank"
                className={`underline inline text-link-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}
              >
                {t("privacy_policy")}
              </a>
            }
            name="accept_terms"
            reference={register}
            error={errors.accept_terms}
            disabled={loading}
          />

          <div className="my-4">
            <ButtonMain
              width={"full"}
              type="button"
              onClick={async () => {
                if (await trigger()) onSubmit(getValues());
              }}
              text={t("register_form.sign_up_button")}
              disabled={loading}
            />
          </div>

          <span className="text-xs text-gray font-regular">
            {t('register_form.already_have_an_account_question')}
            <Link to={`${RoutesLinks.LOGIN_LINK}`} className={`underline inline text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM} ml-1`}>
              {t('register_form.navigate_to_login_link')}
            </Link>
          </span>
        </form>
      </div>

      <BottomText text={t("copyright_rights", { year: moment().format("YYYY") })} />
    </section>
  );
};

export default RegisterForm;
