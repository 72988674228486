import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { Container } from 'components/ui/objects/Container'
import { RoutesLinks } from 'components/routes-links'
import ForgotPasswordForm from './ForgotPasswordForm'
import AuthService from 'services/auth-service'

import BgLogin from 'assets/images/Login-vertical.png'
import Logo from 'assets/images/Logo-difree.svg'

const ForgotPassword = () => {
  const { addToast } = useToasts()

  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const handleSubmit = async ({ email }) => {
    try {
      setEmail(email)
      await AuthService.forgotPassword(email)
      setEmailSent(true)
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    }
  }

  if (emailSent) {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.FORGOT_RESEND_LINK,
          state: { email }
        }}
      />
    )
  } else {
    return (
      <Container variant="session">
        <Container>
          <ForgotPasswordForm onSubmit={handleSubmit} />
        </Container>
        <div className='hidden lg:block'>
        <div className='items-center justify-center hidden w-full h-screen bg-center bg-cover lg:flex' style={{ backgroundImage: `url(${BgLogin})` }}>
            <img src={Logo} className="max-w-[60%] max-h-1/2" alt="Logo" />
        </div>
      </div>
      </Container>
    )
  }
}

export default ForgotPassword
