import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { Loader } from "components/ui/molecules/Loader";
import { useHistory } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { useSelector } from "react-redux";
import { Card } from "components/ui/atoms/Card";
import moment from "moment";
import { Arrow, useLayer } from "react-laag";
import Config from "config";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { FaFilter } from "react-icons/fa";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import { usePagination } from "hooks/usePagination";
import { BiSort } from "react-icons/bi";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import DiFreeReportsFiltersSection from "./ReportsFiltersSection";
import ReportsService from "services/reports-service";
import { Pagination } from "components/ui/atoms/Pagination";
import { PopoverReport } from "./PopoverReport";

import EntreCompBG from "assets/images/report-entrecomp.jpg";
import DigCompBG from "assets/images/report-digcomp.jpg";
import useUser from "hooks/useUser";

const BG_REPORT = {
  entrecomp: EntreCompBG,
  digcomp: DigCompBG,
};

const Reports = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [reports, setReports] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [showFilterReports, setshowFilterReports] = useState(false);
  const formInitialized = useRef(false);
  const userLang = useSelector((state) => state.user.preferences.language.code);

  const [selectedLanguage, setSelectedLanguage] = useState(userLang);

  const { setTotalPages, totalPages, setPage, page, rowsPerPage, setRowsPerPage } = usePagination(12);

  const history = useHistory();

  const handleRedirect = (url) => history.push(url);
  const [error, setError] = useState(false);
  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: () => setOpen(false),
    onDisappear: () => setOpen(false),
    overflowContainer: true,
    auto: true,
    placement: "bottom-center",
    triggerOffset: 12,
    containerOffset: 16,
    arrowOffset: 16,
  });

  const getReports = async () => {
    try {
      setLoading(true);
      const result = await ReportsService.getReports({
        ...filters,
        rowsPerPage,
        page,
        sort,
      });
      setReports(result.data.data.reports);
      if (result.data.data.reports?.length) {
        setshowFilterReports(true);
      }
      setError(false);
      setTotalPages(result.data.data.pagination.pages);
    } catch (error) {
      setError(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const downloadReport = async (reportId) => {
    try {
      setLoading(true);
      const result = await ReportsService.getReport(reportId, selectedLanguage);
      window.open(result.data.data, "_blank");
    } catch (error) {
      console.error(error);
      addToast(t("reports.error_occurred_downloading_report"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!formInitialized.current) {
      formInitialized.current = true;
      return;
    }

    if (page > 0) {
      setPage(0);
      return;
    }
    getReports();
  }, [filters]);

  useEffect(() => {
    getReports();
  }, [page, rowsPerPage, sort]);

  return (
    <>
      {showFilters && (
        <DiFreeReportsFiltersSection onClose={() => setShowFilters(false)} onFilter={setFilters} filters={filters} />
      )}
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={t("page_titles.reports")} showBack={false} />
        </div>
        <div className="flex">
          {showFilterReports && (
            <>
              <div data-tip={t("sort")} className={"mr-2"}>
                <button
                  className={`text-text-buttons-secondary-${Config.PLATFORM} bg-bg-buttons-secondary-${Config.PLATFORM} border border-border-buttons-secondary-${Config.PLATFORM} focus:outline-none custom-circle hover:shadow-inner shadow-soft-white w-10 h-8 rounded-lg flex items-center justify-center text-center`}
                  {...triggerProps}
                  onClick={() => setOpen(!isOpen)}
                >
                  <BiSort className="w-4 h-4" />
                </button>
              </div>
              <ButtonSecondary
                iconComponent={<FaFilter className="h-4" />}
                onClick={() => {
                  setShowFilters(true);
                }}
              />
            </>
          )}
          {isOpen &&
            renderLayer(
              <ul
                {...layerProps}
                className={`mt-2 px-2 py-2 z-30 | shadow-hover border bg-white rounded-2xl border-border-buttons-secondary-${Config.PLATFORM}`}
              >
                {[
                  { title: t("date_asc"), sort: "date_asc" },
                  { title: t("date_desc"), sort: "date_desc" },
                ].map((i, index) => (
                  <li
                    key={index}
                    onClick={() => setSort(i.sort)}
                    className={`px-2 py-2 flex text-main-${Config.PLATFORM} items-center cursor-pointer text-sm text-gray`}
                  >
                    <span className={`mr-2 text-main-${Config.PLATFORM}`}>{i.icon}</span>
                    <span className="hover:font-bold">{t(i.title)}</span>
                  </li>
                ))}
                <Arrow
                  {...arrowProps}
                  borderColor={Config.PLATFORM === "000" ? "#61D8BD" : "#8ECAE6"}
                  borderWidth={1}
                  className={"w-5"}
                />
              </ul>
            )}
        </div>
      </div>

      {loading && <Loader />}
      {!error && reports.length > 0 && (
        <>
          <div className="grid grid-cols-1 gap-8 mt-6 md:grid-cols-2 2xl:grid-cols-3">
            {reports.map((report, index) => {
              return (
                <>
                  <div className="relative w-full h-40 mb-4 group rounded-t-2xl">
                    <div className="relative w-full h-40 overflow-hidden rounded-t-2xl">
                      <div
                        className="flex items-center justify-center w-full h-40 rounded-t-2xl"
                        style={{
                          backgroundImage: `url(${BG_REPORT[report.evaluation_type]})`,
                          backgroundSize: "cover",
                        }}
                      >
                        <div className="absolute inset-0 transition-opacity duration-300 bg-white bg-opacity-80 group-hover:opacity-50"></div>
                        <div className={`text-main-${Config.PLATFORM} text-center p-2 relative z-10`}>
                          <h2 className="mb-2">{t(`evaluations.${report.evaluation_type}`)}</h2>
                          <div className="px-3 text-base font-semibold text-center text-black">
                            {moment(report?.evaluation_date).format("DD/MM/YYYY")}
                          </div>
                        </div>
                        <div className="absolute top-0 right-0 mt-2 mr-2">
                          <div className="relative">
                            <PopoverReport selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="py-2 text-xs font-semibold text-center text-black cursor-pointer rounded-b-2xl"
                      style={{ backgroundColor: "#8ECAE6" }}
                      onClick={() => downloadReport(report._id)}
                    >
                      {t("reports.view_report")}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <Pagination
            currentPage={page}
            setCurrentPage={(page) => setPage(page)}
            perPage={rowsPerPage}
            pages={totalPages}
            setPerPage={(value) => setTotalPages(value[0].id)}
          />
        </>
      )}
      {!error && reports.length === 0 && !loading && (
        <div className="mt-6">
          <div className="flex flex-col items-center p-6 bg-white rounded-lg">
            <div className={`my-6 flex flex-row justify-center text-main-${Config.PLATFORM}`}>
              {t("reports.reports_not_found")}
            </div>
            <div className="flex justify-center w-full md:w-1/4">
              <ButtonMain text={t("evaluations.go_to_evaluations")} onClick={() => handleRedirect(RoutesLinks.EVALUATIONS_LINK)} />
            </div>
          </div>
        </div>
      )}
      {error && (
        <div className="mt-6">
          <div className="flex flex-col items-center p-6 bg-white rounded-lg">
            <div className={`my-6 flex flex-row justify-center text-main-${Config.PLATFORM}`}>
              {t("reports.error_occurred_description")}
            </div>
            <div className="flex justify-center w-full md:w-1/4">
              <ButtonMain text={t("buttons.reload_info")} onClick={() => getReports()} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Reports;
