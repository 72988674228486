import { useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { RoutesLinks } from 'components/routes-links'
import { FormTitle } from 'components/ui/atoms/FormTitle'
import { Input } from 'components/ui/atoms/Input'
import { BottomText } from 'components/ui/atoms/BottomText'
import ShowPassword from 'assets/images/ShowPassword.png'
import moment from 'moment'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Logo from 'assets/images/Logo.png'

const ConfirmRecoverPasswordForm = ({ onSubmit }) => {
  const { t } = useTranslation()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const handleShowhide = () => {
    setShowPassword(!showPassword)
  }

  const handleShowhideConfirm = () => {
    setShowConfirm(!showConfirm)
  }

  const ConfirmRecoverPasswordSchema = Yup.object().shape({
    new_password: Yup.string()
      .required(t('form_validation.required_field'))
      .min(8, t('form_validation.password_length'))
      .matches(/^.*(?=.*?[A-Z]).*$/, t('password_req_uppercase'))
      .matches(/^.*(?=.*?[a-z]).*$/, t('password_req_lowercase'))
      .matches(/^.*(?=.*?[0-9]).*$/, t('password_req_number'))
      .matches(
        /^.*(?=.*?[\^ $ * . [ \] { } ( ) ? " ! @ # % & , > < ' : ; _ ~ ` \\ / |]).*$/,
        t('password_req_special_char')
      ),
    password_confirmation: Yup.string()
      .min(8, t('form_validation.password_length'))
      .oneOf([Yup.ref('new_password'), null], t('passwords_do_not_match'))
      .required(t('form_validation.required_field'))
  })

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(ConfirmRecoverPasswordSchema, { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'all'
  })

  return (
    <section className="flex flex-col justify-center items-center h-full w-full">
      <div className="md:w-full px-10">
        <div className="flex justify-center items-center block lg:hidden">
          <img src={Logo} alt="Difree Logo" className="object-fit" />
        </div>
        <div className="flex flex-col">
          <>
            <FormTitle formTitle={t('recover_password')} />
            <div className="text-black text-base">
              <p className="pb-2">{t('confirm_forgot_instructions')}</p>
            </div>
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-4">
                <Input
                  disabled={isSubmitting}
                  reference={register}
                  id="new_password"
                  type={showPassword ? 'text' : 'password'}
                  name="new_password"
                  label={t('forgot_password_confirm.new_password_label')}
                  placeholder={t('forgot_password_confirm.new_password_placeholder')}
                  error={errors}
                  required={true}
                  icon={ShowPassword}
                  show={handleShowhide}
                />
                <Input
                  disabled={isSubmitting}
                  reference={register}
                  icon={ShowPassword}
                  id="password_confirmation"
                  type={showConfirm ? 'text' : 'password'}
                  name="password_confirmation"
                  required={true}
                  label={t('forgot_password_confirm.confirm_password_label')}
                  placeholder={t('forgot_password_confirm.confirm_password_placeholder')}
                  error={errors.password_confirmation}
                  show={handleShowhideConfirm}
                />
              </div>
              <div className='my-4'>
                <ButtonMain
                  type={'submit'}
                  width={'full'}
                  paddingVertical={3}
                  text={t('buttons.restore_password')}
                />
              </div>
            </form>
            <div className="w-full text-center mt-4 text-xs">
              <Link to={`${RoutesLinks.LOGIN_LINK}`} className="underline">
                {t('forgot_password_confirm.navigate_to_login_link')}
              </Link>
            </div>
          </>
        </div>
      </div>
      <BottomText text={t('copyright_rights', { year: moment().format('YYYY') }) }/>
    </section>
  )
}

export default ConfirmRecoverPasswordForm
