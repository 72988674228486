import { PopoverMenu } from 'components/ui/atoms/PopoverMenu'
import Logo from 'assets/images/Logo.png'
import { AiOutlineMenu } from 'react-icons/ai'

const Header = ({ handleResponsiveSidebar }) => {
  return (
    <header>
      <div className="sm:hidden p-3 pr-10 w-full flex justify-between items-center bg-white">
        <div>
          <AiOutlineMenu onClick={() => handleResponsiveSidebar()} className='cursor-pointer w-8 h-8'/>
        </div>
        <div className='text-center flex flex-row items-center justify-center w-full'>
          <img src={Logo} alt="Difree Logo" className="object-fit" />
        </div>
      </div>
      <div className='hidden sm:block flex justify-end'>
        <div className="p-3 pr-10 w-full flex justify-end items-center">
          <div className='flex jutify-end'>
            <div className="flex items-center" id="header-options">
              <PopoverMenu />
            </div>
          </div>
      </div>
      </div>
    </header>
  )
}

export default Header
