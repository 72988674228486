import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import { SidePanel } from 'components/ui/objects/SidePanel'
import Select from 'components/ui/atoms/Select'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Config from 'config'
import { isEmpty } from 'lodash'

const ReportsFiltersSection = ({
  onFilter,
  onClose,
  filters = {},
}) => {

  const { t } = useTranslation()
  const { addToast } = useToasts()


  const evaluationTypes = [{ id: 'entrecomp', value: t('evaluations.entrecomp') }, { id: 'digcomp', value: t('evaluations.digcomp') }]

  const {
    register,
    handleSubmit,
    trigger,
    errors,
    setValue,
    reset,
    watch
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
      evaluation_type: Yup.string().nullable(true)
    }), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
  }

  const filter = (filters) => {
    onFilter && onFilter({...filters, exclusive: true})
    onClose && onClose()
  }

  const resetFilters = () => {
    reset()
    filter({})
  }

  const onInvalid = (error) => {
    console.error(error)
    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
  }

  useEffect(() => {
    register('evaluation_type')
  }, [register])

  useEffect(() => {
    if (filters && !isEmpty(filters)) {
    _setValue('evaluation_type', filters.evaluation_type, { shouldValidate: true })
    }
  }, [filters, register])

  return (
    <SidePanel title={t('reports_filters_section.title')} onClose={() => onClose && onClose()} width={'1/4'}>
      <form
        className="flex flex-col w-full"
        onSubmit={handleSubmit(filter, onInvalid)}
      >
        <div>
         <Select
            label={t('reports_filters_section.evaluation_type_label')}
            name={'evaluation_type'}
            items={evaluationTypes}
            multiSelect={false}
            isClearable={true}
            initialValues={watch('evaluation_type') ? [evaluationTypes.find((evaluationTypeOption) => evaluationTypeOption.id === watch('evaluation_type'))] : []}
            onSelect={selection => _setValue('evaluation_type', selection.length ? selection[0].id : null)}
          />
        </div>

        <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
          <ButtonSecondary
            text={t('reports_filters_section.clear_filters_button')}
            onClick={() => resetFilters()}
            type="button"
            marginRight={2}
          />
          <ButtonMain
            text={t('reports_filters_section.filter_button')}
            type="submit"
          />
        </div>
      </form>
    </SidePanel>
  )
}

export default ReportsFiltersSection
