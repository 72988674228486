import React, { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { Loader } from 'components/ui/molecules/Loader'
import { useHistory } from 'react-router-dom'
import { RoutesLinks } from 'components/routes-links'
import { useSwal } from 'hooks/useSwal'
import Config from 'config'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { FaEye, FaPaperPlane, FaTimes } from 'react-icons/fa'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import EvaluationsService from 'services/evaluations-service'
import useUser from 'hooks/useUser'
import EntreCompQuestions from './EntreCompQuestions.json'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { RiArrowLeftSLine } from 'react-icons/ri'
import EvaluationProgressSection from './EvaluationProgressSection'

const Entrecomp = () => {
    const { user } = useUser()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { alert, confirm } = useSwal()
    const [error, setError] = useState(false)
  const [evaluation, setEvaluation] = useState(null)

  const [loading, setLoading] = useState(true)

    const [currentIndex, setCurrentIndex] = useState(0)


  const [selectedOption, setSelectedOption] = useState(null)

  const history = useHistory()

  const handleRedirect = (url) => history.push(url)
    const [showEvaluationProgressSection, setShowEvaluationProgressSection] = useState(false)

  useEffect(() => {
    if (!(user.date_of_birth && user.gender && user.nationality)) {
        handleRedirect(RoutesLinks.EVALUATIONS_LINK)
    }
  }, [user])

  const getSectionIcon = (section) => {
    return <HiOutlineLightBulb className='inline mx-2 mb-2 h-5 w-5' />
    }

    const saveAndContinue = async () => {
        // const currentIndex = EntreCompQuestions.findIndex((q) => q.section === currentSection && q.subsection === currentSubsection)
        try {
            setLoading(true)
            const evaluationCopy = { ...evaluation }
            const indexOfCurrentQuestion = evaluationCopy.questions.findIndex((q) => q.section === EntreCompQuestions[currentIndex].section && q.subsection === EntreCompQuestions[currentIndex].subsection)
            if (indexOfCurrentQuestion >= 0 ) {
                evaluationCopy.questions[indexOfCurrentQuestion].answer = selectedOption
            } else {
                evaluationCopy.questions.push({ section: EntreCompQuestions[currentIndex].section, subsection: EntreCompQuestions[currentIndex].subsection, answer: selectedOption })
            }
            await EvaluationsService.updateEntrecompEvaluation(evaluationCopy)
            getEntrecompEvaluation()
            setCurrentIndex(currentIndex + 1)
            setSelectedOption(null)
        } catch (error) {
            addToast(t('evaluations.error_occurred_saving_evaluation'), {
                appearance: 'error',
                autoDismiss: true
              })
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const confirmExitEvaluation = () => {
        confirm({ text: t('evaluations.exit_evaluation_confirmation_text') }).then(isConfirmed => {
            if (isConfirmed) {
                handleRedirect(RoutesLinks.EVALUATIONS_LINK)
            }
        })
    }

    const confirmSubmitEvaluation = () => {
        if (evaluation.questions.some((item) => item.answer !== null)) {
            confirm({ text: t('evaluations.submit_evaluation_confirmation_text') }).then(async(isConfirmed) => {
                if (isConfirmed) {
                    try {
                        setLoading(true)
                        await EvaluationsService.submitEntrecompEvaluation()
                        addToast(t('evaluations.evaluation_submitted_successfully'), {
                            appearance: 'success',
                            autoDismiss: true
                        })
                        handleRedirect(RoutesLinks.EVALUATIONS_LINK)
                    } catch (error) {
                        addToast(t('evaluations.error_occurred_submitting_evaluation'), {
                            appearance: 'error',
                            autoDismiss: true
                        })
                        console.error(error)
                    } finally {
                        setLoading(false)
                    }
                }
            })
        } else {
            alert({ text: t('evaluations.submit_evaluation_disabled_text') })
        }
    }

    const startEvaluation = async () => {
        try {
          setLoading(true)
          const result = await EvaluationsService.startEntrecompEvaluation()
            setEvaluation(result.data.data ? !result.data.data.completed ? result.data.data : null : null)
        } catch (error) {
          console.error(error)
          addToast(t('evaluations.error_occurred_starting_evaluation'), {
            appearance: 'error',
            autoDismiss: true
          })
        } finally {
            setLoading(false)
        }
      }

    const getEntrecompEvaluation = async () => {
        try {
          setLoading(true)
          const result = await EvaluationsService.getEntrecompEvaluation()
          setEvaluation(result.data.data ? !result.data.data.completed ? result.data.data : null : null)
          setError(false)
        } catch (error) {
            setError(true)
            console.error(error)
        } finally {
            setLoading(false)
        }
      }
    
      useEffect(() => {
        getEntrecompEvaluation()
      }, [])

      useEffect(() => {
        if (evaluation && !evaluation.completed && evaluation.questions.length && currentIndex < EntreCompQuestions.length) {
            const currentQuestion = evaluation.questions.find((q) => q.section === EntreCompQuestions[currentIndex].section && q.subsection === EntreCompQuestions[currentIndex].subsection)
            if (currentQuestion) {
                setSelectedOption(currentQuestion.answer)
            }
        }
      }, [evaluation, currentIndex])

  return (
    <>
        <div className="flex items-start">
            <div className='flex-1'>
                <PageTitle
                    title={t('page_titles.entrecomp_evaluation')}
                />
            </div>
        </div>
        {error && (
            <div className="mt-6">
                <div className='bg-white rounded-lg p-6 flex items-center flex-col'>
                    <div className={`my-6 flex flex-row justify-center text-main-${Config.PLATFORM}`}>{t('evaluations.entrecomp_evaluation_error_occurred_description')}</div>
                    <div className='w-full md:w-1/4 flex justify-center'>
                        <ButtonMain text={t('buttons.reload_info')} onClick={() => getEntrecompEvaluation()} />
                    </div>
                </div>
            </div>
        )}
        {showEvaluationProgressSection && <EvaluationProgressSection onClose={() => setShowEvaluationProgressSection(false)} questions={evaluation.questions} setCurrentIndex={setCurrentIndex} evaluation={'entrecomp'} />}
        {loading && <Loader/>}
        {evaluation && <div className={`cursor-pointer p-2 rounded-lg text-white flex justify-between items-center bg-main-${Config.PLATFORM}`} onClick={() => setShowEvaluationProgressSection(true)}>
            <div className='text-base font-semibold'>
                {`${t('evaluations.check_the_process')}: ${evaluation.questions.filter((q) => q.answer !== null).length}/${EntreCompQuestions.length}`}
            </div>
            <div>
                <FaEye className='inline'/>
            </div>
        </div>}

        {!loading && !evaluation && !error && (
            <div className='my-2'>
                <div className={`text-main-${Config.PLATFORM}`}>{t('evaluations.entrecomp_subtitle')}</div>
                <div style={{ backgroundColor: '#8ECAE6' }} className='my-6 p-6 rounded-lg'>
                    <div className='my-2'>
                        <Trans
                            i18nKey='evaluations.entrecomp_explanation_first_point'
                            components={{ bold: <strong /> }}
                        />
                    </div>
                    <div className='my-2'>
                        <Trans
                            i18nKey='evaluations.entrecomp_explanation_second_point'
                            components={{ bold: <strong /> }}
                        />
                    </div>
                    <div className='my-2'>
                        <Trans
                            i18nKey='evaluations.entrecomp_explanation_third_point'
                            components={{ bold: <strong /> }}
                        />
                    </div>
                    <div className='my-2'>
                        <Trans
                            i18nKey='evaluations.entrecomp_explanation_fourth_point'
                            components={{ bold: <strong /> }}
                        />
                    </div>
                    <div className='my-2'>
                        <Trans
                            i18nKey='evaluations.entrecomp_explanation_fifth_point'
                            components={{ bold: <strong /> }}
                        />
                    </div>
                </div>
                <div className='mt-4 flex justify-between'>
                    <div>
                        <ButtonSecondary iconComponent={<FaTimes />} onClick={() => confirmExitEvaluation()} />
                    </div>
                        <ButtonMain
                            width={'full lg:w-1/2'}
                            text={t('buttons.start_evaluation')}
                            type={'button'}
                            onClick={() => startEvaluation()}
                        />
                </div>
            </div>
        )}
        {evaluation && currentIndex !== null && currentIndex < EntreCompQuestions.length && (
            <div className='my-6'>
                <div key={`entrecomp_sections.${EntreCompQuestions[currentIndex].section}`}>
                    <span className='font-semibold'>{t(`entrecomp_sections.${EntreCompQuestions[currentIndex].section}`)}</span>{getSectionIcon(EntreCompQuestions[currentIndex].section)}<span>{t(`entrecomp_subsections.${EntreCompQuestions[currentIndex].subsection}`)}</span>
                    <div className={`text-xs text-main-${Config.PLATFORM}`}>{t(`entrecomp_subsections_description.${EntreCompQuestions[currentIndex].section}_${EntreCompQuestions[currentIndex].subsection}`)}</div>
                    <div className='my-6 p-2 rounded-lg text-white' style={{ backgroundColor: '#7996D0' }}>
                        <div className='font-semibold'>
                            {`${currentIndex + 1} - ${t(`entrecomp_subsections_question.${EntreCompQuestions[currentIndex].section}_${EntreCompQuestions[currentIndex].subsection}`)}`}
                        </div>
                        <div className='text-xs'>
                            {t('evaluations.question_intro')}
                        </div>
                    </div>
                    {['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eigth', 'nineth'].map((option, optionIndex) => {
                        return (
                            <div onClick={() => {
                                setSelectedOption(optionIndex === selectedOption ? null : optionIndex)}} key={`option_${optionIndex}`} className={`${optionIndex === selectedOption && `bg-secondary-${Config.PLATFORM}`} cursor-pointer flex border border-gray-lines rounded-lg my-2 p-2 hover:bg-secondary-${Config.PLATFORM}`}>
                                <div className='flex items-center mr-2'><div className={'border border-gray-lines rounded-full p-1 w-8 flex justify-center'}>{optionIndex}</div></div><div className='flex items-center'>{t(`entrecomp_evaluation_options.${EntreCompQuestions[currentIndex].section}_${EntreCompQuestions[currentIndex].subsection}_${option}_option`)}</div>
                            </div>
                        )
                    })}
                </div>
                <div className='mt-4 flex justify-between'>
                    <div className='flex'>
                        <ButtonSecondary iconComponent={<FaTimes />} onClick={() => confirmExitEvaluation()} />
                        <ButtonSecondary marginLeft={2} iconComponent={<FaPaperPlane />} onClick={() => confirmSubmitEvaluation()} />
                    </div>
                    <div className='flex'>
                        {currentIndex > 0 && <ButtonSecondary marginRight={2} iconComponent={<RiArrowLeftSLine />} onClick={() => setCurrentIndex(currentIndex - 1)} />}
                        <ButtonMain
                            text={t(currentIndex === EntreCompQuestions.length - 1 ? 'buttons.save_and_finish_evaluation' : 'buttons.save_and_continue_evaluation')}
                            type={'button'}
                            onClick={() => saveAndContinue()}
                        />
                    </div>
                </div>
            </div>
        )}
        {evaluation && currentIndex !== null && currentIndex === EntreCompQuestions.length && (
            <div className='my-6'>
                <div className='flex flex-col mb-6'>
                    <div className={` flex flex-row justify-center text-main-${Config.PLATFORM}`}>
                        {t('evaluations.evaluation_end_reached')}
                    </div>
                    <div className='flex flex-row justify-center my-4'>
                        <ButtonSecondary iconComponent={<FaEye className='inline mr-1'/>} text={t('evaluations.review_questions')} onClick={() => setCurrentIndex(0)} />
                        <ButtonSecondary marginLeft={2} iconComponent={<FaPaperPlane className='inline mr-1' />} text={t('evaluations.submit_evaluation')} onClick={() => confirmSubmitEvaluation()} />
                    </div>
                </div>
                <div className='mt-4 flex justify-start'>
                    <ButtonSecondary iconComponent={<FaTimes />} onClick={() => confirmExitEvaluation()} />
                </div>
            </div>
        )}

    </>
  )
}

export default Entrecomp
