import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import Config from 'config'
import { RoutesLinks } from 'components/routes-links'
import { Link } from 'react-router-dom'

import { FormTitle } from 'components/ui/atoms/FormTitle'

import { Input } from 'components/ui/atoms/Input'
import { BottomText } from 'components/ui/atoms/BottomText'
import moment from 'moment'

import 'styles/main.css'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Logo from 'assets/images/Logo-difree.svg'

const LoginForm = ({
  onSubmit,
  loading
}) => {
  const { t } = useTranslation()



  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form_validation.incorrect_format'))
      .required(t('form_validation.required_field')),
    password: Yup.string()
      .required(t('form_validation.required_field'))
  })

  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(LoginSchema)
  })

  return (
    <section className="flex flex-col items-center justify-start w-full h-full lg:justify-center">
      <div className="w-full px-8">
        <div className="flex items-center justify-center mt-8 lg:hidden">
          <img src={Logo} alt="Difree Logo" className="h-20 object-fit" />
        </div>
        <FormTitle
          formTitle={t('login.title')}
        />
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <Input
            reference={register}
            id="email"
            type="email"
            name="email"
            required={true}
            label={t('login.email_label')}
            placeholder={t('login.email_placeholder')}
            error={errors.email}
            disabled={!!loading}
          />
          <div className="mt-4">
            <Input
              reference={register}
              id="password"
              type='password'
              name="password"
              label={t('login.password_label')}
              required={true}
              placeholder={t('login.password_placeholder')}
              error={errors.password}
              disabled={!!loading}
            />
          </div>

          {loading
            ? (
              <span className={`text-xs text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM} font-semibold cursor-default`}>
                {t('login.forgot_password_link')}
              </span>
            )
            : (
              <Link to={`${RoutesLinks.FORGOT_LINK}`} className={`text-xs text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}>
                {t('login.forgot_password_link')}
              </Link>
            )}

          {/* <Checkbox
            reference={register}
            id="keep_logged"
            name="keep_logged"
            label="Keep me logged in"
          /> */}

          <div className='my-4'>
            <ButtonMain
              type="submit"
              text={t('login.login_button')}
              disabled={!!loading}
              width={'full'}
              paddingVertical={3}
            />
          </div>
          <span className="mr-1 text-xs font-medium text-gray">
            {t('login.not_registered_yet_question')}
          </span>
          <Link
            to={`${RoutesLinks.REGISTER_LINK}`}
            className={`underline text-xs text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}
            disabled={!!loading}
          >
            {t('login.create_account_link')}
          </Link>
        </form>
      </div>
      <BottomText text={t('copyright_rights', { year: moment().format('YYYY') }) }/>
    </section>
  )
}

export default LoginForm
