/* eslint-disable camelcase */

const languages = require('./assets/json/languages')
const Config = require('./config')

const langs = {}

const importAll = (r) => {
  r.keys().forEach(
    (key) => (langs[key.split('./')[1]?.split('.json')[0]] = r(key))
  )
}

importAll(require.context('./assets/json/languages/', false, /\.(json)$/))

const navigatorlangCode = (navigator.language || navigator.userLanguage).split(
  '-'
)[0]

let currentLang = languages[0]

const lang_code = localStorage.getItem('lang_code')

if (lang_code) {
  currentLang = languages.find((language) => {
    return language.code === lang_code
  })
} else {
  languages.forEach((language) => {
    if (language.code === navigatorlangCode) {
      currentLang = language
    }
  })
}

const setLang = (langCode) => {
  languages.forEach((language) => {
    if (language.code === langCode) {
      currentLang = language
      localStorage.setItem('lang_code', langCode)
    }
  })
}

const getCurrentLang = () => {
  return currentLang
}

const getLanguages = () => languages

const getRawJsonFromLangCode = (code) => {
  return langs[code]
}

export default {
  setLang,
  getCurrentLang,
  getLanguages,
  getRawJsonFromLangCode
}
